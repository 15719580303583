import ReactGA from "react-ga4";
import { useEffect, useState } from "react";
import Layout from "../components/layout/Layout";
import { useCollectDataMutation, useCollectEventMutation } from "../store";

const existingDeviceId = localStorage.getItem('deviceId');

export default function Singup() {
    const [collectEvent] = useCollectEventMutation();
    const [collectData] = useCollectDataMutation();

    const [email, setEmail] = useState('');
    const [msg, setMsg] = useState('');

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            collectData({ deviceId: existingDeviceId, email });
        }, 3000)

        return () => clearTimeout(delayDebounceFn)
    }, [email])


    const eventServiceInformation = (to) => {        
        const { category, action, label, value } = {
            category: 'Service Subscription',
            action: 'Interest',
            label: to,
            value: 1
        }
        ReactGA.event({ category, action, label, value });
        collectEvent({ deviceId: existingDeviceId, category, action, label, value });
    } 

    const handleSubmit = (event) => {
        event.preventDefault();
        setMsg('Recebido! Espere notícias em breve no seu e-mail');
        collectData({ deviceId: existingDeviceId, email });
        eventServiceInformation('Waiting List No Price Shown');
    }

    return (
        <>
            <Layout>
                <section className="page-title layout-02">
                    <div className="container">
                        <div className="inner align-center">
                            <h1 className="title">Vamos avisá-lo quando estiver disponível!</h1>
                        </div>
                    </div>
                </section>
                <div className="container mb-5 pb-5">
                    <form onSubmit={handleSubmit} className="sign-in">
                        <div className="field-input">
                            <label htmlFor="email">Email*</label>
                            <input type="text" name="email" id="email" placeholder="ex: joao@escritoriodojoao.com.br"
                                value={email} onChange={(event) => setEmail(event.target.value)}
                                autoFocus
                            />
                        </div>
                        <div className="field-submit text-center">
                            <input type="submit" name="submit" value="Entrar na lista de espera" style={{ marginRight: "20px" }} />
                        </div>
                        <br />
                        <strong>{msg}</strong>
                    </form>
                </div>
            </Layout>

        </>
    )
}
