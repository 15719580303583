export const theme = {
    global: {
        colors: {
            focus: '#ffdd0f',
        },
        focus: {
            border: {
                color: '#ffdd0f'
            }
        }
    }
}