
import HeaderRight from './HeaderRight';
import Logo from '../Logo';
import Hamburger from './Hamburger';
import Title from './Title';

export default function HeaderApp({headerClass}) {
    return (
        <>
            <header id="header" className={`site-header admin-header ${headerClass}`}>
            <Hamburger />
                <div className="container-fluid">
                    <div className="row flex-align-c inner">
                        <div className="col-lg-3 col-6 no-pad width-250">
                            <div className="header-left flex flex-align-c">
                                <Logo />
                            </div>
                        </div>
                        <div className="col-lg-6 col-0 text-cente width-less-550">
                            <div className="header-center">
                                <Title />
                            </div>
                        </div>
                        <div className="col-lg-3 col-6 no-pad width-300">
                            <HeaderRight />
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}