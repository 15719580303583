import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const mainApi = createApi({
    reducerPath: "main",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.NODE_ENV === "production" ? process.env.CLIENT : "http://localhost:3000"
    }),
    endpoints(builder) {
        return {
            collectData: builder.mutation({
                query: (data) => {
                    return {
                        url: `/api/register`,
                        method: 'POST',
                        body: data
                    }
                }
            }),
            fetchNup: builder.query({
                query: (nup) => {
                    return {
                        url: `/api/nup/${nup}`,
                        method: 'GET'
                    }
                },
                providesTags: ['current_nup']
            }),
        }
    }
});

export const {
    useFetchNupQuery, 
    useCollectDataMutation
} = mainApi;
export { mainApi };