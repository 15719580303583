import { useDispatch, useSelector } from "react-redux";
import ClickableImage from "../elements/ClickableImage";
import { setLeftMenu } from "../../store";

import sidebar from "../../img/ui/dark/sidebar.svg";

const OpenLeftMenu = () => {
    const dispatch = useDispatch();
    const { left_menu } = useSelector((state) => state.ui);

    return (
        <div
            className="browser-open-sidebar"
            onClick={() => dispatch(setLeftMenu(!left_menu))}
        >
            <ClickableImage
                imageClass="browser-open-sidebar-img hidden-sm"
                alt="Abrir menu lateral"
                image={sidebar}
                disabled={false}
            />
        </div>
    )
}

export default OpenLeftMenu;