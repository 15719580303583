import { Helmet } from "react-helmet";

const PageHead = ({ headTitle }) => {
    return (
        <>
            <Helmet>
                <title>
                    {headTitle ? headTitle : "Justiça Simples: Consulta Processual Unificada"}

                </title>
                <link
                    href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;900&display=swap"
                    rel="stylesheet"
                />
            </Helmet>
        </>
    );
};

export default PageHead;