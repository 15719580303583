import { createSlice } from '@reduxjs/toolkit';

const historySlice = createSlice({
    name: "history",
    initialState: {
        searched: localStorage.getItem('history') ? JSON.parse(localStorage.getItem('history')) : []
    },
    reducers: {
        addHistory(state, action) {
            state.searched.push(action.payload);
            state.searched = [...new Set(state.searched)];
            localStorage.setItem('history', JSON.stringify(state.searched));
        },
    }
})

export default historySlice.reducer
export const {
    addHistory,
} = historySlice.actions