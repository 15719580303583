import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import TrafficLights from "./TrafficLights";
import OpenLeftMenu from "./OpenLeftMenu";
import Navigator from "./Navigator";
import SearchBar from "./SearchBar";
import ToolBar from "./ToolBar";

import { addHistory, useCollectNupMutation } from "../../store";

const existingDeviceId = localStorage.getItem('deviceId');

const TopBar = () => {
    const { nup } = useParams();
    const [ collectNup ] = useCollectNupMutation();

    const dispatch = useDispatch();
    const valid_nup = (/^([0-9]{7}-[0-9]{2}\.[0-9]{4}\.[0-9]\.[0-9]{2}\.[0-9]{4})$/.test(nup));

    useEffect(() => {
        if (valid_nup) {
            dispatch(addHistory(nup));
            collectNup({ deviceId: existingDeviceId, nup: nup });
        }
    }, [dispatch, valid_nup, nup])

    return (
        <div className="browser-top">
            <TrafficLights />
            <OpenLeftMenu />
            <Navigator />
            <SearchBar />
            <ToolBar />
        </div>

    )
}

export default TopBar;