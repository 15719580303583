import { Notification } from "grommet";
import { useSelector } from "react-redux";

const Notifications = () => {
    const { notifications } = useSelector((state) => state.ui);

    return (
        <>
            {notifications && (
                <div className="position-fixed top-0 end-0 translate-middle-x mt-2" style={{ zIndex: "1000" }}>
                    {notifications.map(({title, message}) => (
                        <Notification
                            className="greenish mb-2"
                            status="normal"
                            title={title}
                            message={message}
                        />
                    )
                    )}
                </div>
            )}
        </>
    )
}

export default Notifications;