import { useDispatch, useSelector } from "react-redux";
import { setJurisdiction, useFetchLogicQuery } from "../../../store";

const LabNavigator = () => {
    const { selectedJurisdiction } = useSelector((state) => state.labUi);
    const { data } = useFetchLogicQuery();
    const dispatch = useDispatch();
    document.onkeydown = checkKey;

    function checkKey(e) {
        if (data) {
            const index = data.findIndex(obj => obj === selectedJurisdiction);
            const maxIndex = data.length;
            e = e || window.event;

            if (e.metaKey && e.shiftKey && e.keyCode == '37') {
                e.preventDefault();

                const backIndex = (index === 0) ? (maxIndex - 1) : (index - 1);
                dispatch(setJurisdiction(data[backIndex]));
            } else if (e.metaKey && e.shiftKey && e.keyCode == '39') {
                e.preventDefault();

                const nextIndex = (index === maxIndex) ? 0 : (index + 1);
                dispatch(setJurisdiction(data[nextIndex]));
            }
        }

    }
}

export default LabNavigator;