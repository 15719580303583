import ReactGA from "react-ga4";
import { useCollectEventMutation } from "../../../../store";

import { MaskedInput } from "grommet";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function HomeHero() {
    const [nup, setNup] = useState('');
    const navigate = useNavigate();

    const existingDeviceId = localStorage.getItem('deviceId');
    const [collectEvent] = useCollectEventMutation();

    const eventServiceInformation = (to) => {        
        const { category, action, label, value } = {
            category: 'Service Information',
            action: 'Interact',
            label: to,
            value: 1
        }
        ReactGA.event({ category, action, label, value });
        collectEvent({ deviceId: existingDeviceId, category, action, label, value });
    } 

    const handleSubmit = (event) => {
        event.preventDefault();
        navigate('/' + event.target.nup.value);
        eventServiceInformation('Search Button Home Hero');
    }

    return (
        <>
            <section className="section opt120 spdb overflow-hidden" id="Home">
                <div className="container">
                    <div className="row flex-align-c">
                        <div className="col-lg-6">
                            <div>
                                <div className="heading mb32">
                                    <div className="heading-sub layout-02">Gratuito</div>
                                    <h2 className="heading-title size-xl">Todos os <span className="no-underline background-04">tribunais</span><br /> em um só lugar</h2>
                                    <div className="heading-desc">Digite o número único do processo:</div>
                                </div>

                                <form className="newsletter" style={{ margin: "0" }} onSubmit={handleSubmit}>
                                    <div className="field-input">
                                        <MaskedInput
                                            mask={[
                                                {
                                                    length: 7,
                                                    regexp: /^[0-9]{1,7}$/,
                                                    placeholder: '_______',
                                                },
                                                { fixed: '-' },
                                                {
                                                    length: 2,
                                                    regexp: /^[0-9]{1,2}$/,
                                                    placeholder: '__',
                                                },
                                                { fixed: '.' },
                                                {
                                                    length: 4,
                                                    regexp: /^[0-9]{1,4}$/,
                                                    placeholder: '____',
                                                },
                                                { fixed: '.' },
                                                {
                                                    length: 1,
                                                    regexp: /^[0-9]{1}$/,
                                                    placeholder: '_',
                                                },
                                                { fixed: '.' },
                                                {
                                                    length: 2,
                                                    regexp: /^[0-9]{1,2}$/,
                                                    placeholder: '__',
                                                },
                                                { fixed: '.' },
                                                {
                                                    length: 4,
                                                    regexp: /^[0-9]{1,4}$/,
                                                    placeholder: '____',
                                                },
                                            ]}
                                            value={nup}
                                            onChange={(event) => setNup(event.target.value)}
                                            onPaste={(event) => {
                                                const paste_filtered = event.clipboardData.getData('text/plain').replace(/\D/g,'');
                                                const combine = nup + paste_filtered;
                                                const limited_size = combine.substring(0, 25);
                                                setNup(limited_size);
                                            }}
                                            name="nup"
                                            style={{ height: "60px" }} autoFocus
                                        />
                                    </div>
                                    <div className="field-submit">
                                        <input type="submit" name="submit" value="Pesquisar" />
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <img src="/assets/images/hst-01.png" alt="Image" />
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}