import ReactGA from "react-ga4";
import { useCollectEventMutation } from "../../../../store";

export default function SectionHow() {
    const existingDeviceId = localStorage.getItem('deviceId');
    const [collectEvent] = useCollectEventMutation();


    const eventServiceInformation = (to) => {        
        const { category, action, label, value } = {
            category: 'Service Information',
            action: 'Interact',
            label: to,
            value: 1
        }
        ReactGA.event({ category, action, label, value });
        collectEvent({ deviceId: existingDeviceId, category, action, label, value });
    }

    return (
        <>
            <section className="section background-grey spdtb" id="How">
                <div className="container">
                    <div className="heading align-center">
                        <div className="heading-sub color-navy">Como funciona</div>
                        <h2 className="heading-title size-l">Acesso Rápido e Fácil a Processos</h2>
                    </div>
                    <div className="block-icon-box layout-03">
                        <div className="row">
                            <div className="col-lg-6 cursor" onClick={() => eventServiceInformation('How1 - Search')}>
                                <div className="icon-box p48 background-white">
                                    <div className="inner">
                                        <div className="icon">
                                            <img src="/assets/images/services-04.svg" alt="Icon" />
                                        </div>
                                        <div className="content">
                                            <h3 className="title">Pesquise um processo</h3>
                                            <div className="desc">Clique em "Pesquisar" para encontrar um processo.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 cursor" onClick={() => eventServiceInformation('How2 - Bookmark')}>
                                <div className="icon-box p48 background-white">
                                    <div className="inner">
                                        <div className="icon">
                                            <img src="/assets/images/services-03.svg" alt="Icon" />
                                        </div>
                                        <div className="content">
                                            <h3 className="title">Salve nos favoritos</h3>
                                            <div className="desc">Pressione Ctrl + D ou ⌘ + D para salvar a consulta nos seus favoritos.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 cursor" onClick={() => eventServiceInformation('How3 - Share')}>
                                <div className="icon-box p48 background-white">
                                    <div className="inner">
                                        <div className="icon">
                                            <img src="/assets/images/services-06.svg" alt="Icon" />
                                        </div>
                                        <div className="content">
                                            <h3 className="title">Compartilhe por e-mail</h3>
                                            <div className="desc">Envie o link da consulta por e-mail para compartilhar um processo.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 cursor" onClick={() => eventServiceInformation('How4 - Spreadsheets')}>
                                <div className="icon-box p48 background-white">
                                    <div className="inner">
                                        <div className="icon">
                                            <img src="/assets/images/services-01.svg" alt="Icon" />
                                        </div>
                                        <div className="content">
                                            <h3 className="title">Inclua em planilhas</h3>
                                            <div className="desc">Inclua links em planilhas eletrônicas para acessar mais e mais rápido.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}