import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const labApi = createApi({
    reducerPath: "lab",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.NODE_ENV === "production" ? process.env.CLIENT : "http://localhost:3000"
    }),
    endpoints(builder) {
        return {
            fetchLogic: builder.query({
                query: () => {
                    return {
                        url: `/api/lab`,
                        method: 'GET'
                    }
                },
                providesTags: ['lab']
            }),
            updateLogic: builder.mutation({
                query: ({JTR, jurisdiction}) => {
                    return {
                        url: `/api/lab/${JTR}`,
                        method: 'post',
                        body: jurisdiction
                    }
                },
                invalidatesTags: ['lab']
            }),
        }
    }
});

export const {
    useFetchLogicQuery,
    useUpdateLogicMutation,
} = labApi;
export { labApi };