import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import WOW from 'wowjs';

import Footer1 from './Footer/Footer1';
import HeaderLanding from './Header/HeaderLanding';
import HeaderApp from './Header/HeaderApp';
import HeaderDev from './Header/HeaderDev';
import PageHead from './PageHead';
import LeftMenu from './LeftMenu';
import Notifications from './Notifications';
import PageView from './PageView';

export default function Layout({ backgroundClass, wrapperClass, headerLayout, footerLayout, children, siteContentClass, pageTitle, pageTitleSub, pageTitleDesc, pageTitleBtn, headerClass }) {
    const { nup } = useParams();
    const { left_menu } = useSelector((state) => state.ui);

    useEffect(() => {
        window.wow = new WOW.WOW({
            live: false
        });
        window.wow.init();
    });

    useEffect(() => {
        document.getElementById("top").scrollIntoView({
            behavior: 'smooth'
        });
    }, []);

    return (
        <div id="top" >
            <PageView />
            <PageHead />
            <div id="wrapper" className={wrapperClass}>
                {!headerLayout && < HeaderLanding headerClass={headerClass} />}
                {headerLayout === 'app' ? < HeaderApp headerClass={headerClass} /> : null}
                {headerLayout === 'dev' ? < HeaderDev headerClass={headerClass} /> : null}
                <div className={'row ' + backgroundClass}>
                    {left_menu && nup && <LeftMenu />}
                    <main id="main" className={"site-main " + (left_menu && "width-less-250")}>
                        {pageTitle &&
                            <section className="page-title">
                                <div className="container">
                                    <div className="inner align-center">
                                        {pageTitleSub && <div className="sub">{pageTitleSub}</div>}
                                        {pageTitle && <h1 className="title">{pageTitle}</h1>}
                                        {pageTitleDesc && <p className="desc">{pageTitleDesc}</p>}
                                        {pageTitleBtn &&
                                            <div className="button-wrap"><a href="#" className="button fullfield" title="Opportunities">{pageTitleBtn}</a></div>
                                        }
                                    </div>
                                </div>
                            </section>
                        }
                        <div className={`site-content ${siteContentClass ? siteContentClass : ""}`}>
                            {children}
                        </div>
                    </main>
                </div>
            </div>

            {!footerLayout && < Footer1 />}
            {footerLayout === 1 ? < Footer1 /> : null}

            <Notifications />
        </div>
    )
}