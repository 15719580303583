import Layout from "../components/layout/Layout";

export default function Terms() {
    return (
        <>
            <Layout>
                <section className="page-title layout-02">
                    <div className="container">
                        <div className="inner align-center">
                            <h1 className="title">Termos de Uso</h1>
                        </div>
                    </div>
                </section>
                <div className="container mb-5 pb-5 spaced" style={{ maxWidth: "960px" }}>

                    <h4>Bem-vindo ao Justiça Simples.</h4>
                    <p>Aqui, queremos que você tenha a melhor experiência possível e, por isso, preparamos uma seção de Perguntas Frequentes (FAQ) para responder às dúvidas mais comuns dos usuários.</p>

                    <h3>1. Condições de Uso</h3>
                    <p>Este documento é essencial para entender como interagir e usar os serviços do Justiça Simples. Leia com atenção antes de começar a usar a plataforma. Ao utilizar nossos serviços, você concorda com nossas Condições de Uso e a nossa Política de Privacidade, e reconhece que a Benti Technologies detém todos os direitos de propriedade intelectual sobre a plataforma.</p>
                    <p>O Justiça Simples é uma ferramenta que facilita a busca por conteúdo jurídico, com a finalidade de conectar pessoas à justiça. Lembre-se de que o uso da plataforma exige que você aceite nossos termos.</p>

                    <h3>2. Conta de Usuário</h3>
                    <p>Para aproveitar todos os recursos do Justiça Simples, é necessário se registrar e, em alguns casos, realizar o pagamento devido. Nós podemos solicitar algumas informações pessoais durante o registro. É importante que você mantenha esses dados atualizados. Saiba que a sua privacidade é uma prioridade para nós, sendo protegida de acordo com nossa Política de Privacidade.</p>
                    <p>Você é responsável por proteger sua conta e senha e deve informar-nos imediatamente sobre qualquer uso indevido. Menores de 18 anos precisam de autorização dos responsáveis para usar nossos serviços. Além disso, você não pode transferir ou vender sua conta para outra pessoa.</p>

                    <h3>3. Recursos e Serviços</h3>
                    <p>Oferecemos ferramentas para aprimorar a pesquisa de processos judiciais, com diferentes planos de preços claramente indicados no nosso site. As informações obtidas através do Justiça Simples não substituem a consulta a documentos oficiais. O usuário é responsável por verificar as informações nos órgãos oficiais.</p>
                    <p>Os nossos serviços são cobrados em ciclos de 30 dias, com renovação automática, a menos que o usuário cancele. Em caso de cancelamento, o acesso continua até o fim do ciclo atual. Lembramos que não fornecemos consultoria jurídica ou qualquer outro serviço descrito na Lei nº 8.906/1994.</p>

                    <h3>4. Propriedade Intelectual</h3>
                    <p>Os termos de uso concedem aos usuários uma licença limitada para usar a plataforma e os serviços. Todas as marcas, logotipos, imagens e outros direitos de propriedade intelectual pertencem ao Justiça Simples e estão protegidos por lei. Qualquer uso não autorizado desses direitos é proibido.</p>

                    <h3>5. Limitações de Responsabilidade</h3>
                    <p>O Justiça Simples não é responsável pelo conteúdo publicado pelos usuários, nem endossa opiniões. Não nos responsabilizamos por danos causados pelo uso indevido dos serviços ou por erros de informações.</p>

                    <h3>6. Sanções</h3>
                    <p>A não conformidade com os Termos de Uso poderá resultar na suspensão ou rescisão dos serviços do Justiça Simples. Em caso de violação de direitos de terceiros, o usuário concorda em indenizar o Justiça Simples e a Benti Technologies por todas as perdas, danos e despesas.</p>

                    <h3>7. Alterações aos Termos de Uso</h3>
                    <p>A Benti Technologies pode revisar e atualizar os Termos de Uso do Justiça Simples periodicamente. As mudanças entram em vigor no momento da publicação. Ao continuar a usar a plataforma após a publicação de novos termos, você aceita as alterações.</p>

                    <h3>8. Lei Aplicável</h3>
                    <p>O uso dos serviços e a interpretação destes Termos de Uso estão sujeitos às leis do Brasil. Qualquer litígio relativo ao Justiça Simples será resolvido nos tribunais brasileiros.</p>

                    <h3>9. Contato</h3>
                    <p>Em caso de dúvidas ou problemas relacionados ao uso do Justiça Simples, por favor entre em contato conosco através do endereço de email disponível na seção "Contato" do nosso site.</p>

                    <h3>10. Conclusão</h3>
                    <p>Esperamos que o Justiça Simples ajude a conectar você à justiça de maneira mais eficaz e acessível. Nossa missão é facilitar a vida de todos que buscam informações jurídicas. Agradecemos por escolher nossos serviços.</p>

                </div>
            </Layout>

        </>
    )
}
