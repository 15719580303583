import ReactGA from "react-ga4";
import ClickableImage from "../elements/ClickableImage";
import open from "../../img/ui/dark/open.svg";
import share from "../../img/ui/dark/share.svg";
import copy from "../../img/ui/dark/copy.svg";
import { notified, notify, setRightMenu, useCollectEventMutation, useFetchNupQuery } from "../../store";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ShareMenu from "../layout/ShareMenu";

const ToolBar = () => {
    const { nup } = useParams();
    const valid_nup = (/^([0-9]{7}-[0-9]{2}\.[0-9]{4}\.[0-9]\.[0-9]{2}\.[0-9]{4})$/.test(nup));
    const { data, isError, isFetching, isLoading } = useFetchNupQuery(valid_nup ? nup : null);
    const disabled = (isError || isFetching || isLoading);

    const dispatch = useDispatch();
    const { right_menu } = useSelector((state) => state.ui);

    const existingDeviceId = localStorage.getItem('deviceId');
    const [collectEvent] = useCollectEventMutation();

    const eventAppInteraction = (to) => {        
        const { category, action, label, value } = {
            category: 'App',
            action: 'Interact',
            label: to,
            value: 1
        }
        ReactGA.event({ category, action, label, value });
        collectEvent({ deviceId: existingDeviceId, category, action, label, value });
    }

    const handleOpen = () => {
        if (!disabled && data) {
            window.open(data.open, '_blank')
        }
        eventAppInteraction('Open Court Site at ToolBar');
    };

    const handleShare = () => {
        dispatch(setRightMenu(!right_menu));
        eventAppInteraction('Share Menu at ToolBar');
    }

    const handleCopy = (event) => {
        event.preventDefault();
        navigator.clipboard.writeText('https://justicasimpl.es/' + (valid_nup ? nup : ""));
        dispatch(notify({title:"Copiado", message:"Link copiado para a área de trabalho!"}));
        setTimeout(() => {
            dispatch(notified());
        }, 2000);
        eventAppInteraction('Copy Link at TollBar');
    }

    return (
        <div className="browser-toolbar-group">
            <div className="browser-toolbar-item">
                <ClickableImage
                    imageClass="browser-toolbar-img0"
                    alt="Abrir site do tribunal"
                    image={open}
                    disabled={disabled}
                    onClick={handleOpen}
                />
            </div>
            <div className="browser-toolbar-item">
                <ClickableImage
                    imageClass="browser-toolbar-img1"
                    alt="Compartilhar"
                    image={share}
                    disabled={disabled}
                    onClick={handleShare}
                />
                <ShareMenu />
            </div>
            <div className="browser-toolbar-item">
                <ClickableImage
                    imageClass="browser-toolbar-img3"
                    alt="Copiar link"
                    image={copy}
                    disabled={disabled}
                    onClick={handleCopy}
                />
            </div>
        </div>
    )
}

export default ToolBar;