import ReactGA from "react-ga4";
import { useNavigate, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { useCollectEventMutation, setLeftMenu } from "../../../store";

export default function Hamburger() {
    const { nup } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const existingDeviceId = localStorage.getItem('deviceId');
    const [collectEvent] = useCollectEventMutation();

    const eventServiceInformation = (to) => {        
        const { category, action, label, value } = {
            category: 'Service Information',
            action: 'Interact',
            label: to,
            value: 1
        }
        ReactGA.event({ category, action, label, value });
        collectEvent({ deviceId: existingDeviceId, category, action, label, value });
    } 

    const { left_menu } = useSelector((state) => state.ui);

    const toggleMenu = () => {
        if (!nup) {
            dispatch(setLeftMenu(true));
            navigate('/Bem-vindo!');
            eventServiceInformation('Hamburger Button at Home');
        } else {
            dispatch(setLeftMenu(!left_menu));
            eventServiceInformation('Hamburger Button at App');
        }
    };

    return (
        <>
            <div className="canvas-menu hidden-sm" onClick={toggleMenu}>
                <div className="icon cursor-pointer">
                    <svg
                        width="30px"
                        height="14px"
                        className="hamburger"
                        viewBox="0 0 30 14"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                    >
                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <g transform="translate(-50.000000, -33.000000)" fill="#111111">
                                <g id="off-menu" transform="translate(50.000000, 28.000000)">
                                    <g id="icon-menu" transform="translate(0.000000, 5.000000)">
                                        <rect
                                            id="Rectangle-1"
                                            x={0}
                                            y={0}
                                            width={30}
                                            height={3}
                                            rx="1.5"
                                        />
                                        <rect
                                            id="Rectangle-2"
                                            x={0}
                                            y={11}
                                            width={20}
                                            height={3}
                                            rx="1.5"
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>

                <div className="icon cursor-pointer d-none">
                    <svg
                        width="30px"
                        height="14px"
                        viewBox="0 0 30 14"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                    >
                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <g transform="translate(-50.000000, -33.000000)" fill="#9f9f9f">
                                <g id="off-menu" transform="translate(50.000000, 28.000000)">
                                    <g id="icon-menu" transform="translate(0.000000, 5.000000)">
                                        <rect
                                            id="Rectangle-1"
                                            x={0}
                                            y={0}
                                            width={30}
                                            height={3}
                                            rx="1.5"
                                        />
                                        <rect
                                            id="Rectangle-2"
                                            x={0}
                                            y={11}
                                            width={20}
                                            height={3}
                                            rx="1.5"
                                        />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>
            </div>
        </>
    );

}