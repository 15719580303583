import { Box, MaskedInput, Tip, Text } from "grommet";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { notified, notify } from "../../../store";
import { useState } from "react";

const InvalidNup = () => {
    const [nup, setNup] = useState('');
    const valid_nup = (/^([0-9]{7}-[0-9]{2}\.[0-9]{4}\.[0-9]\.[0-9]{2}\.[0-9]{4})$/.test(nup));

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleSubmit = (event) => {
        event.preventDefault();
        navigate('/' + event.target.nup.value);
    }

    const handleCopy = (event) => {
        event.preventDefault();
        navigator.clipboard.writeText('https://justicasimpl.es/' + (valid_nup ? nup : ""));
        dispatch(notify({ title: "Copiado", message: "Link copiado para a área de trabalho!" }));
        setTimeout(() => {
            dispatch(notified());
        }, 2000);
    }

    return (
        <div className="iframe d-flex justify-content-center align-items-center flex-column" style={{ padding: "64px", backgroundColor: "transparent" }}>
            <h3 style={{ textAlign: "center", width: "350px" }}>
                Procure por um processo no <Tip
                    content={
                        <Box pad="small" gap="small" width={{ max: 'small' }}>
                            <Text weight="bold">Formato do CNJ</Text>
                            <>
                                <Text size="small">
                                    O número único do processo possui 20 dígitos distribuídos no formato NNNNNNN-DD.AAAA.J.TR.OOOO
                                </Text>
                            </>
                        </Box>}
                    dropProps={{ align: { left: 'right' } }}
                >
                    formato do CNJ
                </Tip>
            </h3>
            <form onSubmit={handleSubmit} className="text-center">
                <div className="field-input">
                    <MaskedInput
                        mask={[
                            {
                                length: 7,
                                regexp: /^[0-9]{1,7}$/,
                                placeholder: '_______',
                            },
                            { fixed: '-' },
                            {
                                length: 2,
                                regexp: /^[0-9]{1,2}$/,
                                placeholder: '__',
                            },
                            { fixed: '.' },
                            {
                                length: 4,
                                regexp: /^[0-9]{1,4}$/,
                                placeholder: '____',
                            },
                            { fixed: '.' },
                            {
                                length: 1,
                                regexp: /^[0-9]{1}$/,
                                placeholder: '_',
                            },
                            { fixed: '.' },
                            {
                                length: 2,
                                regexp: /^[0-9]{1,2}$/,
                                placeholder: '__',
                            },
                            { fixed: '.' },
                            {
                                length: 4,
                                regexp: /^[0-9]{1,4}$/,
                                placeholder: '____',
                            },
                        ]}
                        value={nup}
                        onChange={(event) => setNup(event.target.value)}
                        onPaste={(event) => {
                            const paste_filtered = event.clipboardData.getData('text/plain').replace(/\D/g,'');
                            const combine = nup + paste_filtered;
                            const limited_size = combine.substring(0, 25);
                            setNup(limited_size);
                        }}
                        name="nup"
                        style={{
                            marginTop: "40px",
                            width: "min(100vw - 40px, 400px)",
                            height: "60px"
                        }}
                        autoFocus
                    />
                </div>
                <div className="field-submit">
                    <a className="button cursor" onClick={handleCopy}>Copiar link</a>
                    <input type="submit" name="submit" value="Pesquisar" disabled={!nup}/>
                </div>
            </form>
        </div >
    )
}

export default InvalidNup;