import ReactGA from "react-ga4";
import { useCollectEventMutation } from "../../../../store";
import { Link } from "react-router-dom";

export default function SectionCTA() {
    const existingDeviceId = localStorage.getItem('deviceId');
    const [collectEvent] = useCollectEventMutation();

    const eventServiceInformation = (to) => {        
        const { category, action, label, value } = {
            category: 'Service Information',
            action: 'Interact',
            label: to,
            value: 1
        }
        ReactGA.event({ category, action, label, value });
        collectEvent({ deviceId: existingDeviceId, category, action, label, value });
    }

    return (
        <section className="section align-center mb-5 spdb">
            <div className="block-trial layout-01">
                <div className="container">
                    <img className="image-top" src="/assets/images/macaroni-vertical.webp" alt="Trial" />
                    <div className="sub">Simplifique a sua rotina!</div>
                    <h2 className="title">Comece agora</h2>
                    <div className="button-wrap">
                        <Link to="/Bem-vindo!" className="button fullfield" title="Consulte um processo"
                            onClick={() => eventServiceInformation('Search Button at CTA Section')}
                        >
                            Consulte um processo
                        </Link></div>
                </div>
            </div>
        </section>
    )
}