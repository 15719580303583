import { createSlice } from '@reduxjs/toolkit';

const labUiSlice = createSlice({
    name: "labUi",
    initialState: {
        selectedJurisdiction: {},
        selectedExample: null,
        selectedUrl: null,
    },
    reducers: {
        setJurisdiction(state, action) {
            state.selectedJurisdiction = action.payload;
        },
        setExample(state, action) {
            state.selectedExample = action.payload;
        },
        setUrl(state, action) {
            state.selectedUrl = action.payload;
        },
    }
})

export default labUiSlice.reducer
export const {
    setExample,
    setJurisdiction,
    setUrl
} = labUiSlice.actions