import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const dataApi = createApi({
    reducerPath: "data",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.NODE_ENV === "production" ? process.env.CLIENT : "http://localhost:3000"
    }),
    endpoints(builder) {
        return {
            collectData: builder.mutation({
                query: (data) => {
                    return {
                        url: `/api/collect`,
                        method: 'POST',
                        body: data
                    }
                },
                invalidatesTags: ['device']
            }),
            collectEvent: builder.mutation({
                query: (data) => {
                    return {
                        url: `/api/collect/event`,
                        method: 'POST',
                        body: data
                    }
                },
            }),
            collectPageview: builder.mutation({
                query: (data) => {
                    return {
                        url: `/api/collect/pageview`,
                        method: 'POST',
                        body: data
                    }
                },
            }),
            collectNup: builder.mutation({
                query: (data) => {
                    return {
                        url: `/api/collect/nup`,
                        method: 'POST',
                        body: data
                    }
                },
            }),
            collectFeedback: builder.mutation({
                query: (data) => {
                    return {
                        url: `/api/collect/feedback`,
                        method: 'POST',
                        body: data
                    }
                },
            }),
            fetchData: builder.query({
                query: (deviceId) => {
                    return {
                        url: `/api/data/${deviceId}`,
                        method: 'GET',
                    }
                },
                providesTags: ['device']
            }),
        }
    }
});

export const {
    useCollectDataMutation,
    useCollectEventMutation,
    useCollectNupMutation,
    useCollectFeedbackMutation,
    useCollectPageviewMutation,
    useFetchDataQuery,
} = dataApi;
export { dataApi };