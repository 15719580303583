import { useEffect } from 'react';
import { setDeviceId, useCollectDataMutation, useFetchDataQuery } from '../store';

import ReactGA from "react-ga4";
import { useDispatch } from 'react-redux';

const SaveData = () => {
    const [collectData] = useCollectDataMutation();
    useEffect(() => {
        const existingDeviceId = localStorage.getItem('deviceId');
        if (!!existingDeviceId) {

            const usefulProperties = [
                'appCodeName',
                'appName',
                'appVersion',
                'platform',
                'userAgent',
                'cookieEnabled',
                'language',
                'doNotTrack',
                'geolocation',
                'hardwareConcurrency',
                'maxTouchPoints'
            ];

            const navigatorInfo = {};
            usefulProperties.forEach((property) => {
                if (property in window.navigator) {
                    if (property === 'geolocation') {
                        navigator.geolocation.getCurrentPosition(
                            (position) => {
                                navigatorInfo['latitude'] = position.coords.latitude;
                                navigatorInfo['longitude'] = position.coords.longitude;
                            }
                        );
                    } else {
                        navigatorInfo[property] = window.navigator[property];
                    }
                }
            });

            collectData({ deviceId: existingDeviceId, navigatorInfo });
        }
    }, []);
}

const CollectData = () => {
    const { data, isError, isFetching, isLoading } = useFetchDataQuery(localStorage.getItem('deviceId'));
    const dispatch = useDispatch();

    useEffect(() => {
        const existingDeviceId = localStorage.getItem('deviceId') ? localStorage.getItem('deviceId') : (Date.now().toString(36)) + (Math.random().toString(36));
        localStorage.setItem('deviceId', existingDeviceId);

        dispatch(setDeviceId(existingDeviceId));

        ReactGA.set({
            debug: true,
            titleCase: false,
            gaOptions: {
                siteSpeedSampleRate: 100,
                userId: existingDeviceId
            }
        });
    }, [])

    if (!isError && !isFetching && !isLoading && !data) {
        return (<SaveData />)
    }
}

export default CollectData;