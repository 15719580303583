import ReactGA from "react-ga4";
import ClickableImage from "../elements/ClickableImage";

import back from "../../img/ui/dark/back.svg";
import next from "../../img/ui/dark/next.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setFullscreen, useCollectEventMutation } from "../../store";

const Navigator = () => {
    const { nup } = useParams();
    const { searched } = useSelector((state) => state.history);
    const { fullscreen } = useSelector((state) => state.ui);
    const disabled = !(Array.isArray(searched) && searched.length > 1);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    var index = 0;
    var newIndex = 0;

    const existingDeviceId = localStorage.getItem('deviceId');
    const [collectEvent] = useCollectEventMutation();

    const eventAppInteraction = (to) => {
        const { category, action, label, value } = {
            category: 'App',
            action: 'Interact',
            label: to,
            value: 1
        }
        ReactGA.event({ category, action, label, value });
        collectEvent({ deviceId: existingDeviceId, category, action, label, value });
    }

    const handleBack = () => {
        index = searched.indexOf(nup);
        if (index >= 0 && index < searched.length) {
            newIndex = ((index - 1) < 0) ? (searched.length - 1) : (index - 1);
        }
        navigate('/' + searched[newIndex]);
        eventAppInteraction('Back Button at Navigator');
    }

    const handleNext = () => {
        index = searched.indexOf(nup);
        if (index >= 0 && index < searched.length - 1) {
            newIndex = ((index + 1) > searched.length - 1) ? ((index + 1) % searched.length) : (index + 1);
        }
        navigate('/' + searched[newIndex]);
        eventAppInteraction('Next Button at Navigator');
    }

    document.onkeydown = checkKey;

    function checkKey(e) {
        e = e || window.event;

        if (e.keyCode == '37') {
            handleBack();
        } else if (e.keyCode == '39') {
            handleNext();
        } else if ((e.keyCode == 10 || e.keyCode == 13) && e.ctrlKey) {
            dispatch(setFullscreen(!fullscreen));
        }
    }

    return (
        <div className="browser-navigate">
            <div className="browser-back" onClick={handleBack}>
                <ClickableImage
                    imageClass="browser-back-img"
                    alt="Processo anterior"
                    image={back}
                    disabled={disabled}
                />
            </div>
            <div className="browser-next" onClick={handleNext}>
                <ClickableImage
                    imageClass="browser-next-img"
                    alt="Próximo processo"
                    image={next}
                    disabled={disabled}
                />
            </div>
        </div>
    )
}

export default Navigator;