import ReactGA from "react-ga4";
import { useState } from "react";
import { CaretUpFill, CaretDownFill } from "grommet-icons";
import { useCollectEventMutation, useVoteRoadmapMutation } from "../../../store";

const VoteRoadmap = ({ votes, JTR, instance }) => {
    const existingDeviceId = localStorage.getItem('deviceId');

    const [selected, setSelected] = useState(false);
    const [hover, setHover] = useState(false);
    const [collectEvent] = useCollectEventMutation();
    const [voteRoadmap] = useVoteRoadmapMutation();

    const eventServiceInformation = (to) => {
        const { category, action, label, value } = {
            category: 'Product',
            action: 'Interest',
            label: to,
            value: 1
        }
        ReactGA.event({ category, action, label, value });
        collectEvent({ deviceId: existingDeviceId, category, action, label, value });
    }

    const vote = () => {
        setSelected(!selected);
        if (selected) {
            voteRoadmap({JTR, instance, direction: "down"});
        } else {
            voteRoadmap({JTR, instance, direction: "up"});
        }
        eventServiceInformation(`Voted ${JTR} ${instance}`);
    }

    return (
        <div
            name="name"
            onClick={() => vote()}
            onMouseOver={() => setHover(true)}
            onMouseOut={() => setHover(false)}
            style={{ cursor: "pointer" }}
        >
            <div
                style={{ position: "relative", opacity: ((selected || hover) ? "1" : "0.4") }}
                title={selected ? "Clique para retirar seu voto" : "Clique para votar"}
            >
                <>
                    <div style={{ position: "absolute", marginLeft: "-30px", marginTop: "-9px", width: "24px" }}>
                        <div className="col-lg-12" style={{ marginBottom: "-10px", opacity: (!selected && hover ? "1" : "0.4") }} >
                            <CaretUpFill color="white" />
                        </div>
                        <div className="col-lg-12" style={{ opacity: (selected && hover ? "1" : "0.4") }} >
                            <CaretDownFill color="white" />
                        </div>
                    </div>
                    <div>
                        {votes} {votes === 1 ? "voto" : "votos"}
                    </div>
                </>
            </div>
        </div>
    )
}

export default VoteRoadmap;