import Layout from "../components/layout/Layout";
import HomeHero from "../components/sections/homepage/Home/HomeHero";
import SectionHow from "../components/sections/homepage/Home/SectionHow";
import SectionCreateLink from "../components/sections/homepage/Home/SectionCreateLink";
import SectionCTA from "../components/sections/homepage/Home/SectionCTA";
import SectionFAQ from "../components/sections/homepage/Home/SectionFAQ";
import SectionWhy from "../components/sections/homepage/Home/SectionWhy";
import SectionShare from "../components/sections/homepage/Home/SectionShare";
import SectionCourts from "../components/sections/homepage/Home/SectionCourts";

const Home = () => {
    return (
        <Layout backgroundClass="background-grey" siteContentClass="pt0 pb0">
            <HomeHero />
            <SectionWhy />
            <SectionCreateLink />
            <SectionHow />
            <SectionCourts />
            <SectionShare />
            <SectionFAQ />
            <SectionCTA />
        </Layout>
    )
}

export default Home;