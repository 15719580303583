import ReactGA from "react-ga4";
import { Link } from "react-router-dom";
import { useCollectEventMutation } from "../../../../store";

export default function SectionCourts() {
    const existingDeviceId = localStorage.getItem('deviceId');
    const [collectEvent] = useCollectEventMutation();

    const eventServiceInformation = (to) => {        
        const { category, action, label, value } = {
            category: 'Service Information',
            action: 'Interact',
            label: to,
            value: 1
        }
        ReactGA.event({ category, action, label, value });
        collectEvent({ deviceId: existingDeviceId, category, action, label, value });
    } 

    return (
        <>
            <section className="section" id="Courts">
                <div className="container spdtb">
                    <div className="heading align-center mb32">
                        <h2 className="heading-title size-l">Principais tribunais brasileiros</h2>
                        <div className="heading-desc size-l w600 color-navy">Consulta unificada</div>
                    </div>
                    <div className="block-list layout-01 block-flex-center block-list-flex mb60">
                        <ul>
                            <li>
                                <i className="las la-check color-navy"></i> Tribunais Estaduais

                            </li>
                            <li>
                                <i className="las la-check color-navy"></i> Tribunais Federais

                            </li>
                            <li>
                                <i className="las la-check color-navy"></i> Tribunais Trabalhistas
                            </li>
                        </ul>
                    </div>
                    <div className="align-center spdb">
                        <div className="button-wrap">
                            <Link to="/1040404-48.2022.8.26.0053" className="button fullfield" title="Veja um exemplo"
                                onClick={() => eventServiceInformation('Example Button at Court Section')}
                            >
                                Veja um exemplo
                            </Link></div>
                    </div>
                </div>
            </section>
        </>
    )
}