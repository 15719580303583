import { useParams } from "react-router-dom";

export default function Title() {
    const { nup } = useParams();
    const valid_nup = (/^([0-9]{7}-[0-9]{2}\.[0-9]{4}\.[0-9]\.[0-9]{2}\.[0-9]{4})$/.test(nup));

    return (
        <div className="main-menu justify-content-center align-items-center">
            <div className="nup-title">
                <h4>{valid_nup ? nup : "Bem-vindo!"}</h4>
            </div>
        </div>
    )
}