import { useEffect } from "react";
import Layout from "../components/layout/Layout"
import LabLeftMenu from "../components/admin/lab/LabLeftMenu";
import LabRightMenu from "../components/admin/lab/LabRightMenu";
import LabMain from "../components/admin/lab/LabMain";
import LabNavigator from "../components/admin/lab/LabNavigator";

export default function Lab() {
    useEffect(() => {
        document.body.classList.add('sala-dark-scheme');
    }, [])

    useEffect(() => () => {
        document.body.classList.remove('sala-dark-scheme');
    }, []);

    return (
        <>
            <LabNavigator />
            <Layout siteContentClass="pt0 pb0" headerLayout="dev">
                <div className="row">
                    <div className="col-lg-2 background-grey">
                        <LabLeftMenu />
                    </div>
                    <div className="col-lg-7">
                        <LabMain />
                    </div>
                    <div className="col-lg-3 background-grey">
                        <LabRightMenu />
                    </div>
                </div>
            </Layout>
        </>
    )
}