import { useDispatch, useSelector } from "react-redux";
import { useUpdateLogicMutation } from "../../../store";
import { useEffect, useState } from "react";

const WidgetJurisdiction = () => {
    const { selectedJurisdiction } = useSelector((state) => state.labUi);
    const [updateLogic] = useUpdateLogicMutation();
    const [formData, setFormData] = useState();

    useEffect(() => {setFormData(selectedJurisdiction.return)}, [selectedJurisdiction])

    if (selectedJurisdiction) {

        const fields = ['url', 'jurisdiction', 'court', 'court_short', 'display', 'open']

        const handleChange = (e) => {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value
            });
        };

        const handleSubmit = (e) => {
            e.preventDefault();
            updateLogic({ JTR: selectedJurisdiction.condition.JTR, jurisdiction: formData });
        };

        return (
            <>
                <h3>
                    {selectedJurisdiction.jurisdiction}
                </h3>
                <ul>
                    <li>Instância: {selectedJurisdiction.instance}</li>
                    <li>Searched: {selectedJurisdiction.searched | 0}</li>
                    <li>Voted: {selectedJurisdiction.voted | 0}</li>
                </ul>
                <form value={formData} onSubmit={handleSubmit} onChange={handleChange}>
                    {fields.map((field) => (
                        <div class="field-input">
                            <label htmlFor={field}>{field} </label>
                            <input type="text" name={field} id={field} value={formData?.[field] ?? ""} />
                        </div>
                    ))}
                    <button type="submit" class="button fullfield" label="Atualizar" />
                </form>
                {JSON.stringify(formData, null, 2)}
            </>
        )
    }
}

const LabRightMenu = () => {
    return (
        <WidgetJurisdiction />
    )
}

export default LabRightMenu;