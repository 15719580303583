import { Spinner } from "grommet";
import { useFetchRoadmapQuery } from "../../../store";
import VoteRoadmap from "./VoteRoadmap";
import React from "react";

const RoadmapTable = () => {
    const { data, isLoading } = useFetchRoadmapQuery();

    if (isLoading) {
        return <Spinner />
    } else if (data) {
        return (
            <table className="full-plan table-responsive">
                <thead>
                    <tr>
                        <th></th>
                        <th>
                            <div className="name">1° Grau</div>
                        </th>
                        <th>
                            <div className="name">2° Grau</div>
                        </th>
                        <th>
                            <div className="name">Tribunais Superiores</div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(data).map((type, j) => (
                        <React.Fragment key={j}>
                            <tr key={j}>
                                <td colSpan={4} className="colspan-full">{type}</td>
                            </tr>
                            {Object.keys(data[type]).map((JTR, i) => {
                                if (data[type][JTR]["1"]) {
                                    return (
                                        <tr key={i}>
                                            <td className="title" data-title="">
                                                {data[type][JTR]["1"]["jurisdiction"].replace(/\s*\(.*?\)\s*/g, '')}
                                            </td>
                                            <td data-title="1° Grau">
                                                {!!data[type][JTR]["1"]["return"] ?
                                                    "Ok"
                                                    :
                                                    <VoteRoadmap JTR={JTR} instance={1} votes={data[type][JTR]["1"]["value"]} />
                                                }
                                            </td>
                                            <td data-title="2° Grau">
                                                {!!data[type][JTR]["2"]["return"] ?
                                                    "Ok"
                                                    :
                                                    <VoteRoadmap JTR={JTR} instance={2} votes={data[type][JTR]["2"]["value"]} />
                                                }
                                            </td>
                                            <td data-title="Tribunais Superiores">
                                                <VoteRoadmap JTR={JTR} instance={2} votes={data[type][JTR]["1"]["value"] + data[type][JTR]["2"]["value"]} />
                                            </td>
                                        </tr>
                                    )
                                }
                            }
                            )}
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        )
    }
}

export default RoadmapTable;