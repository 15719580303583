import ReactGA from "react-ga4";
import { Link } from "react-router-dom";
import { useCollectEventMutation } from "../../../store";

const existingDeviceId = localStorage.getItem('deviceId');

export default function HeaderRightDev() {
    const [collectEvent] = useCollectEventMutation();

    const eventServiceInformation = (to) => {
        const { category, action, label, value } = {
            category: 'Service Information',
            action: 'Interact',
            label: to,
            value: 1
        }
        ReactGA.event({ category, action, label, value });
        collectEvent({ deviceId: existingDeviceId, category, action, label, value });
    }

    const focusTextarea = () => {
        document.getElementById('message').focus();
        eventServiceInformation('Search Button Top Right');
    }

    const scrollToWorkWithUs = () => {
        document.getElementById('work').scrollIntoView({ behavior: 'smooth' });
        eventServiceInformation('Recruiting Button Top Right');
    }

    return (
        <div className="header-right flex flex-align-c flex-content-e">
            <div className="customer">
                <Link onClick={() => scrollToWorkWithUs()}>
                    <i className="las la-user-circle" />
                    <span>Trabalhe conosco</span>
                </Link>
            </div>
            <div className="buttons">
                <Link className="button fullfield" onClick={() => focusTextarea()}>
                    <i className="las la-search" />
                    <span>Dê a sua opinião!</span>
                </Link>
            </div>
        </div>
    )
}