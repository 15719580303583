import Logo from '../Logo';
import Hamburger from './Hamburger';
import BackToTop from "../../elements/BackToTop";
import HeaderRightDev from './HeaderRightDev';

export default function Header({ headerClass }) {
    return (
        <>
            <header className={`site-header ${headerClass}`}>
                <Hamburger />
                <div className="container-fluid">
                    <div className="row flex-align-c inner">
                        <div className="col-lg-3 col-6">
                            <div className="header-left flex flex-align-c">
                                <Logo color="white"/>
                            </div>
                        </div>
                        <div className="col-lg-9 col-6">
                            <HeaderRightDev />
                        </div>
                    </div>
                </div>
            </header>
            <BackToTop />
        </>
    )
}