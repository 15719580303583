import ReactGA from "react-ga4";
import { useEffect, useState } from "react";
import Layout from "../components/layout/Layout";
import { Link } from "react-router-dom";
import { useCollectDataMutation, useCollectEventMutation } from "../store";

const existingDeviceId = localStorage.getItem('deviceId');

export default function Login() {
    const [collectEvent] = useCollectEventMutation();
    const [collectData] = useCollectDataMutation();

    const [email, setEmail] = useState('');
    const [see, setSee] = useState(false);
    const [remember, setRemember] = useState(true);
    const [msg, setMsg] = useState('');

    const eventServiceInformation = (to) => {        
        const { category, action, label, value } = {
            category: 'Service Information',
            action: 'Interact',
            label: to,
            value: 1
        }
        ReactGA.event({ category, action, label, value });
        collectEvent({ deviceId: existingDeviceId, category, action, label, value });
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            collectData({ deviceId: existingDeviceId, email });
            eventServiceInformation('Started Writing Email at Login');
        }, 3000)

        return () => clearTimeout(delayDebounceFn)
    }, [email])

    const handleSubmit = (event) => {
        event.preventDefault();
        setMsg('Por favor verifique seu email ou senha');
    }

    return (
        <>
            <Layout>
                <section className="page-title layout-02">
                    <div className="container">
                        <div className="inner align-center">
                            <h1 className="title">Entrar</h1>
                            <div className="desc">Usuário novo? <Link to='/cadastrar' title="Criar uma conta" onClick={() => eventServiceInformation('Create User at Login')}>Crie uma conta</Link></div>
                        </div>
                    </div>
                </section>
                <div className="container mb-5 pb-5">
                    <form onSubmit={handleSubmit} className="sign-in">
                        <div className="field-input">
                            <label htmlFor="email">Email*</label>
                            <input type="text" name="email" id="email" placeholder="ex: joao@escritoriodojoao.com.br"
                                value={email} onChange={(event) => setEmail(event.target.value)}
                            />
                        </div>
                        <div className="field-input field-password">
                            <label htmlFor="password">Senha*</label>
                            <input type={see ? "text" : "password"} name="password" id="password" placeholder="********" />
                            <i className="lar la-eye view-password" onClick={() => setSee(!see)} />
                        </div>
                        <div className="field-input field-checkbox">
                            <input type="checkbox" id="remember" name="remember" checked={remember} onChange={() => setRemember(!remember)} />
                            <label htmlFor="remember">Lembrar de mim</label>
                        </div>
                        <div className="field-submit">
                            <input type="submit" name="submit" value="Entrar" style={{ marginRight: "20px" }} disabled={!email}/>
                            <Link onClick={() => setMsg(email ? 'Enviamos uma nova senha para o seu email, caso esteja cadastrado conosco.' : 'Preencha seu e-mail e solicite novamente.')}>Esqueceu sua senha?</Link>
                        </div>
                        <br />
                        <strong>{msg}</strong>
                    </form>
                </div>
            </Layout>

        </>
    )
}
