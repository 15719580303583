import ReactGA from "react-ga4";
import { useSwipeable } from 'react-swipeable';
import TopBar from "./TopBar";
import BrowserFrame from "./BrowserFrame";
import Party from "../layout/Background/Party";
import NiceGuy from "./NiceGuy";
import { useNavigate, useParams } from 'react-router-dom';
import { useCollectEventMutation } from '../../store';
import { useSelector } from "react-redux";

const Browser = () => {
    const { nup } = useParams();
    const { searched } = useSelector((state) => state.history);
    const navigate = useNavigate(); 
    var index = 0;
    var newIndex = 0;

    const existingDeviceId = localStorage.getItem('deviceId');
    const [collectEvent] = useCollectEventMutation();

    const eventAppInteraction = (to) => {
        const { category, action, label, value } = {
            category: 'App',
            action: 'Interact',
            label: to,
            value: 1
        }
        ReactGA.event({ category, action, label, value });
        collectEvent({ deviceId: existingDeviceId, category, action, label, value });
    }

    const handleBack = () => {
        index = searched.indexOf(nup);
        if (index >= 0 && index < searched.length) {
            newIndex = ((index - 1) < 0) ? (searched.length - 1) : (index - 1);
        }
        navigate('/' + searched[newIndex]);
        eventAppInteraction('Swiped Back');
    }

    const handleNext = () => {
        index = searched.indexOf(nup);
        if (index >= 0 && index < searched.length - 1) {
            newIndex = ((index + 1) > searched.length - 1) ? ((index + 1) % searched.length) : (index + 1);
        }
        navigate('/' + searched[newIndex]);
        eventAppInteraction('Swiped Next');
    }

    const handlers = useSwipeable({
        onSwipedLeft: handleNext,
        onSwipedRight: handleBack
    });

    return (
        <div id="browser" className="browser-container" {...handlers}>
            <Party />
            <TopBar />
            <BrowserFrame />
            <NiceGuy />
        </div>
    )
}

export default Browser;