import ReactGA from "react-ga4";
import { useCollectEventMutation } from "../../../../store";
import { Link } from "react-router-dom";
import { useState } from 'react';

export default function SectionFAQ() {
    const existingDeviceId = localStorage.getItem('deviceId');
    const [collectEvent] = useCollectEventMutation();

    const eventSupport = (to) => {        
        const { category, action, label, value } = {
            category: 'Support',
            action: 'Request',
            label: to,
            value: 1
        }
        ReactGA.event({ category, action, label, value });
        collectEvent({ deviceId: existingDeviceId, category, action, label, value });
    }

    const [isActive, setIsActive] = useState({
        status: false,
        key: "",
    });

    const handleToggle = (key) => {
        if (isActive.key === key) {
            setIsActive({
                status: false,
            });
        } else {
            setIsActive({
                status: true,
                key,
            });
        }
    };
    return (
        <>
            <section className="section spdt" id="Faq">
                <div className="container">
                    <div className="heading align-center mb60">
                        <h2 className="heading-title size-l">Perguntas frequentes</h2>
                        <p className="heading-desc">
                            Não encontrou sua resposta?{" "}
                            <Link to="mailto:suporte@justicasimples.com.br" title="Contate nosso suporte"
                                onClick={() => eventSupport('Contact Link at FAQ Section')}
                            >
                                Contate nosso suporte
                            </Link>
                        </p>
                    </div>
                    <div className="block-accordion enable layout-02">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className={isActive.key === 1 ? "accordion-item background-grey is-active" : "accordion-item background-grey"} onClick={() => handleToggle(1)}>
                                    <h4 className="title">Posso usar o Justiça Simples de graça?</h4>
                                    <div className="content" style={isActive.key === 1 ? { display: 'block' } : { display: 'none' }}><p>Sim! O Justiça Simples oferece acesso ilimitado a consultas de processos.</p></div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className={isActive.key === 3 ? "accordion-item background-grey is-active" : "accordion-item background-grey"} onClick={() => handleToggle(3)}>
                                    <h4 className="title">Posso criar meus próprios links do Justiça Simples?</h4>
                                    <div className="content" style={isActive.key === 3 ? { display: 'block' } : { display: 'none' }}><p>Sim! Use o link <span className="pre">http://justicasimpl.es/</span> seguido pelo número único do processo no formato do CNJ.</p></div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className={isActive.key === 4 ? "accordion-item background-grey is-active" : "accordion-item background-grey"} onClick={() => handleToggle(4)}>
                                    <h4 className="title">Como uso o Justiça Simples em planilhas eletrônicas?</h4>
                                    <div className="content" style={isActive.key === 4 ? { display: 'block' } : { display: 'none' }}><p>Se você acompanha seus processos em uma planilha eletrônica, como o Excel, pode criar links diretos para suas consultas no Justiça Simples. Basta substituir a referência “A1” pela célula que contém o número do processo no formato do CNJ.</p>
                                    <br/><span className="cursor" onClick={() => navigator.clipboard.writeText('=HIPERLINK("http://justicasimpl.es/"&A1;"Consultar on-line")')}>=HIPERLINK("http://justicasimpl.es/"&A1;<br/>"Consultar on-line")</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className={isActive.key === 5 ? "accordion-item background-grey is-active" : "accordion-item background-grey"} onClick={() => handleToggle(5)}>
                                    <h4 className="title">O que é o número único do processo?</h4>
                                    <div className="content" style={isActive.key === 5 ? { display: 'block' } : { display: 'none' }}>
                                        <p>O número único do processo ("NUP") é a numeração padrão que o Conselho Nacional de Justiça ("CNJ") criou por meio da Resolução nº 65/2008.</p>
                                        <br/><p>O NUP possui 20 dígitos distribuídos no formato <span className="pre">NNNNNNN-DD.AAAA.J.TR.OOOO</span>, em que <span className="pre">NNNNNNN</span> significa o número do processo, <span className="pre">DD</span> significa o dígito verificador, <span className="pre">AAAA</span> significa o ano de ajuizamento, <span className="pre">J</span> significa o órgão do Poder Judiciário, <span className="pre">TR</span> significa o segmento do Poder Judiciário e <span className="pre">0000</span> significa a unidade de origem do processo.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className={isActive.key === 6 ? "accordion-item background-grey is-active" : "accordion-item background-grey"} onClick={() => handleToggle(6)}>
                                    <h4 className="title">Como o Justiça Simples sabe qual tribunal acessar?</h4>
                                    <div className="content" style={isActive.key === 6 ? { display: 'block' } : { display: 'none' }}><p>Nosso algoritmo foi treinado para identificar em qual tribunal o processo está registrado e acessar o site do tribunal diretamente.</p><p>Ele faz isso com base nos comentários dos nossos usuários. Por isso, a sua participação é muito importante para continuarmos evoluindo!</p></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}