import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { mainApi, useFetchNupQuery } from './apis/mainApi';
import { roadmapApi, useFetchRoadmapQuery, useVoteRoadmapMutation } from './apis/roadmapApi';
import { dataApi, useCollectDataMutation, useCollectEventMutation, useCollectNupMutation, useCollectFeedbackMutation, useCollectPageviewMutation, useFetchDataQuery } from './apis/dataApi';
import uiSlice, { setDeviceId, setLeftMenu, setRightMenu, setFullscreen, notified, notify, setElapsedTime, setLastPath, setTopMenuVisible } from './slices/uiSlice';
import historySlice, { addHistory } from './slices/historySlice';
import { labApi, useFetchLogicQuery, useUpdateLogicMutation } from './apis/labApi';
import labUiSlice, { setExample, setJurisdiction, setUrl } from './slices/labUiSlice';

const store = configureStore({
    reducer: {
        ui: uiSlice,
        labUi: labUiSlice,
        history: historySlice,
        [mainApi.reducerPath]: mainApi.reducer,
        [dataApi.reducerPath]: dataApi.reducer,
        [roadmapApi.reducerPath]: roadmapApi.reducer,
        [labApi.reducerPath]: labApi.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
        .concat(mainApi.middleware)
        .concat(dataApi.middleware)
        .concat(roadmapApi.middleware)
        .concat(labApi.middleware)
})

setupListeners(store.dispatch);

export default store;
export { useCollectDataMutation, useCollectEventMutation, useCollectFeedbackMutation, useCollectNupMutation, useCollectPageviewMutation, useFetchDataQuery };
export { useFetchNupQuery };
export { useFetchRoadmapQuery, useVoteRoadmapMutation };
export { setDeviceId, setLeftMenu, setRightMenu, setFullscreen, notified, notify, setElapsedTime, setLastPath, setTopMenuVisible };
export { addHistory };
export { useFetchLogicQuery, useUpdateLogicMutation };
export { setExample, setJurisdiction, setUrl };