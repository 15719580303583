import { useEffect } from "react";

import Layout from "../components/layout/Layout";
import Browser from "../components/browser/Browser";


export default function Logged() {
    useEffect(() => {
        document.body.style.overflow = "hidden";
        document.getElementById("root").style.overflow = "hidden";
        document.getElementById("wrapper").style.overflow = "hidden";
    }, []);

    return (
        <Layout backgroundClass="background-grey" headerLayout='app' footerLayout='none' wrapperClass="admin" siteContentClass="d-flex justify-content-center align-items-center">
            <Browser />
        </Layout >
    )
}