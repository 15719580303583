import React from 'react';
import ReactGA from "react-ga4";

import { Grommet } from 'grommet';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './assets/libs/bootstrap/css/bootstrap.min.css';
import './assets/libs/line-awesome/css/line-awesome.min.css';
import 'animate.css';
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";
import './assets/css/style.css';

import "./styles.css";

import Home from './screens/Home';
import Login from './screens/Login';
import Signup from './screens/Signup';
import Nup from "./screens/Nup";
import CollectData from './data/CollectData';
import Terms from './screens/Terms';
import Privacy from './screens/Privacy';
import { theme } from './theme';
import Roadmap from './screens/Roadmap';
import Blog from './screens/Blog';
import Lab from './screens/Lab';

ReactGA.initialize('G-2JRX50QW9K');

const App = () => {
    return (
        <Grommet full theme={theme} id="grom">
            <CollectData />
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/blog" element={<Blog />} />
                    <Route path="/entrar" element={<Login />} />
                    <Route path="/cadastrar" element={<Signup />} />
                    <Route path="/termos" element={<Terms />} />
                    <Route path="/privacidade" element={<Privacy />} />
                    <Route path="/roadmap" element={<Roadmap />} />
                    <Route path="/lab" element={<Lab />} />
                    <Route path="/:nup" element={<Nup />} />
                </Routes>
            </BrowserRouter>
        </Grommet>
    )
}

export default App;
