import ReactGA from "react-ga4";
import { Link, useLocation } from "react-router-dom";
import { useCollectEventMutation } from "../../../store";

const existingDeviceId = localStorage.getItem('deviceId');

export default function TopMenu() {
    const location = useLocation();
    const [collectEvent] = useCollectEventMutation();

    const scroll = (to) => {
        document.getElementById(to).scrollIntoView({ behavior: "smooth" });

        const { category, action, label, value } = {
            category: 'Service Information',
            action: 'View',
            label: to,
            value: 1
        }
        ReactGA.event({ category, action, label, value });
        collectEvent({ deviceId: existingDeviceId, category, action, label, value });
    }

    return (
        <>
            {location.pathname === '/' &&
                <div className="main-menu">
                    <div className="menu-action">
                        <span className="item menu-back">
                            <i className="las la-arrow-left" />
                        </span>
                        <span className="item menu-close">
                            <i className="las la-times" />
                        </span>
                    </div>

                    <ul>
                        <li className="is-mega-menu">
                            <Link onClick={() => scroll("Home")}>Home</Link>
                        </li>
                        <li className="is-mega-menu">
                            <Link onClick={() => scroll("How")}>Como funciona</Link>
                        </li>
                        <li className="is-mega-menu">
                            <Link onClick={() => scroll("Courts")}>Tribunais</Link>
                        </li>
                        <li className="is-mega-menu">
                            <Link onClick={() => scroll("Faq")}>Perguntas Frequentes</Link>
                        </li>
                    </ul>

                </div>
            }

        </>
    )
}