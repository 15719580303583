import { Spinner } from "grommet";
import { setExample, setJurisdiction, useFetchLogicQuery } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";

const MenuItem = ({ logic }) => {
    const dispatch = useDispatch();
    const { selectedJurisdiction } = useSelector((state) => state.labUi);
    const isSame = selectedJurisdiction && selectedJurisdiction.condition && (selectedJurisdiction.condition.JTR === logic.condition.JTR);

    const handleClickJurisdiction = (logic) => {
        if (isSame) {
            dispatch(setJurisdiction(null));
            dispatch(setExample(null));
        } else {
            dispatch(setJurisdiction(logic));
            if (logic.examples) {
                dispatch(setExample(logic.examples[0]));
            }
        }
    }

    if (selectedJurisdiction) {
        return (
            <li>
                <a onClick={() => handleClickJurisdiction(logic)} className={isSame ? "cursor is-active" : "cursor"}>
                    {logic.jurisdiction}
                </a>
            </li>
        )
    }
}

const LabLeftMenu = () => {
    const { data, isLoading } = useFetchLogicQuery();

    if (isLoading) {
        return <Spinner />
    } else if (data) {
        return (
            <div className="tab-wrap">
                <ul className="tab-navs">
                    {data.filter((logic) => logic.instance == 1).map((logic, i) => (
                        <MenuItem logic={logic} key={i} />
                    ))}
                </ul>
            </div>
        )
    }
}

export default LabLeftMenu;