import ReactGA from "react-ga4";
import { useParams } from "react-router-dom";
import { useCollectEventMutation, useFetchNupQuery } from "../../store";
import InvalidNup from "./BrowserScreens/InvalidNup";
import { useEffect } from "react";
import FormFeedback from "../elements/FormFeedback";
const existingDeviceId = localStorage.getItem('deviceId');

const HandleHttp = ({url}) => {
    useEffect(() => {
        window.open(url, "_blank");
    }, [url])
}

const BrowserFrame = () => {
    const [collectEvent] = useCollectEventMutation();
    const { nup } = useParams();
    const valid_nup = (/^([0-9]{7}-[0-9]{2}\.[0-9]{4}\.[0-9]\.[0-9]{2}\.[0-9]{4})$/.test(nup));
    const { data, isError, isFetching, isLoading } = useFetchNupQuery(valid_nup ? nup : null);

    const eventFreeServiceUsage = ({ status, nup }) => {
        const { category, action, label, value } = {
            category: 'Free Service Usage',
            action: status,
            label: nup,
            value: 1
        }
        ReactGA.event({ category, action, label, value });
        collectEvent({ deviceId: existingDeviceId, category, action, label, value });
    }

    useEffect(() => {
        if (!isFetching && !isLoading) {
            if (isError) {
                eventFreeServiceUsage({ status: 'Error', nup: nup });
            } else if (data && !valid_nup) {
                eventFreeServiceUsage({ status: 'Invalid NUP', nup: nup });
                // collectFeedback({ deviceId: existingDeviceId, feedback: 'Invalid NUP', nup });
            } else if (data && valid_nup) {
                eventFreeServiceUsage({ status: 'Successful Search', nup: nup });
            }
        }
    }, [data, isFetching, isLoading, isError, nup, valid_nup])

    if (isError) {
        return (
            <div className="iframe d-flex justify-content-center align-items-center" style={{ padding: "64px" }}>
                Algo de errado não está certo
            </div>
        )
    } else if (isFetching || isLoading) {
        return (
            <div className="iframe d-flex justify-content-center align-items-center" style={{ padding: "64px" }}>
                <h3 style={{ color: "#eeeeee" }}>Carregando...</h3>
            </div>
        )
    } else if (data) {
        if (!valid_nup) {
            return (<InvalidNup />)
        } else if ('url' in data) {
            if (data.url.startsWith("https://")) {
                return (
                    <iframe
                        id="browser"
                        className="iframe"
                        src={data.url}
                        title={data.display}
                        autoFocus
                    />
                )
            } else {
                return (
                    <div className="iframe d-flex justify-content-center align-items-center" style={{ padding: "64px" }}>
                        <HandleHttp url={data.url} />
                        <h3><a href={data.url} target="_blank">O site abrirá em uma nova aba <i class="las la-external-link-alt"></i></a></h3>
                    </div>
                )
            }
        } else if ('msg' in data) {
            return (
                <div className="iframe d-flex justify-content-center align-items-center flex-column" style={{ padding: "64px" }}>
                    <FormFeedback
                        question={data.msg}
                        placeholder="Escreva aqui sua mensagem"
                        button="Enviar mensagem"
                        secondaryButton="Roadmap"
                        title="Você pesquisou por"
                        subtitle={nup}
                        finalMessage="Muito obrigado! Você me ensinou algo novo. Eu aprendo com a ajuda de pessoas legais como você."
                    />
                </div>
            )
        }
    } else {
        return (
            <div className="iframe d-flex justify-content-center align-items-center" style={{ padding: "64px" }}>
                Erro desconhecido
            </div>
        )
    }
}

export default BrowserFrame;