import HeaderRight from './HeaderRight';
import Logo from '../Logo';
import Hamburger from './Hamburger';
import TopMenu from './TopMenu';

import BackToTop from "../../elements/BackToTop";
export default function Header({ headerClass }) {
    return (
        <>
            <header className={`site-header ${headerClass}`}>
                <Hamburger />
                <div className="container-fluid">
                    <div className="row flex-align-c inner">
                        <div className="col-lg-3 col-6">
                            <div className="header-left flex flex-align-c">
                                <Logo />
                            </div>
                        </div>
                        <div className="col-lg-6 col-0 text-center no-pad">
                            <div className="header-center">
                                <TopMenu />
                            </div>
                        </div>
                        <div className="col-lg-3 col-6">
                            <HeaderRight />
                        </div>
                    </div>
                </div>
            </header>
            <BackToTop />
        </>
    )
}