import ReactGA from "react-ga4";
import { useEffect, useState } from "react";
import Layout from "../components/layout/Layout";
import { useCollectDataMutation, useCollectEventMutation } from "../store";

const existingDeviceId = localStorage.getItem('deviceId');

export default function Privacy() {
    const [collectEvent] = useCollectEventMutation();
    const [collectData] = useCollectDataMutation();

    const [email, setEmail] = useState('');
    const [msg, setMsg] = useState('');

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            collectData({ deviceId: existingDeviceId, email });
        }, 3000)

        return () => clearTimeout(delayDebounceFn)
    }, [email])


    const eventServiceInformation = (to) => {
        const { category, action, label, value } = {
            category: 'Service Subscription',
            action: 'Interest',
            label: to,
            value: 1
        }
        ReactGA.event({ category, action, label, value });
        collectEvent({ deviceId: existingDeviceId, category, action, label, value });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setMsg('Recebido! Espere notícias em breve no seu e-mail');
        collectData({ deviceId: existingDeviceId, email });
        eventServiceInformation('Waiting List No Price Shown');
    }

    return (
        <>
            <Layout>
                <section className="page-title layout-02">
                    <div className="container">
                        <div className="inner align-center">
                            <h1 className="title">Política de Privacidade</h1>
                        </div>
                    </div>
                </section>
                <div className="container mb-5 pb-5 spaced" style={{ maxWidth: "960px" }}>

                <h4>Bem-vindo ao Justiça Simples.</h4>

                    <h3>1. Introdução</h3>
                    <p>A sua privacidade é uma prioridade para nós, e é nosso compromisso proteger os seus dados pessoais. Esta Política de Privacidade descreve as informações que coletamos, como as usamos, com quem as compartilhamos e quais são os seus direitos em relação a essas informações.</p>

                    <h3>2. Informações que Coletamos</h3>
                    <p>Coletamos as informações que você fornece durante o registro, como nome, email, endereço e outros dados pessoais necessários para a utilização dos nossos serviços.</p>

                    <h3>3. Uso das Informações</h3>
                    <p>Usamos as suas informações para fornecer e melhorar os nossos serviços, atender suas solicitações, personalizar sua experiência e comunicar informações importantes sobre os nossos serviços.</p>

                    <h3>4. Compartilhamento de Informações</h3>
                    <p>Não compartilhamos suas informações pessoais com terceiros, exceto quando necessário para a prestação de nossos serviços ou quando exigido por lei.</p>

                    <h3>5. Segurança</h3>
                    <p>Adotamos medidas de segurança para proteger suas informações contra acesso, alteração, divulgação ou destruição não autorizados. No entanto, nenhum método de transmissão ou armazenamento de dados é completamente seguro e, por isso, não podemos garantir a segurança absoluta das suas informações.</p>

                    <h3>6. Direitos do Usuário</h3>
                    <p>Você tem o direito de acessar, corrigir, atualizar, exportar ou excluir suas informações pessoais a qualquer momento. Se você desejar exercer algum desses direitos, por favor entre em contato conosco.</p>

                    <h3>7. Alterações à Política de Privacidade</h3>
                    <p>Podemos atualizar esta Política de Privacidade periodicamente. Quaisquer mudanças serão publicadas nesta página e entram em vigor imediatamente após a publicação. Ao continuar a usar nossos serviços após as alterações, você aceita a Política de Privacidade atualizada.</p>

                    <h3>8. Contato</h3>
                    <p>Se você tiver alguma dúvida ou preocupação sobre esta Política de Privacidade, por favor entre em contato conosco através do endereço de email suporte@justicasimples.com.br.</p>

                </div>
            </Layout>

        </>
    )
}
