import { Link } from "react-router-dom";
import logo from "../../img/logo-product.svg";
import logo_white from "../../img/logo-product-white.svg";

export default function Logo({color}) {
    return (
        <>
            <div className="logo">
                <Link to="/">
                    <img src={color === 'white' ? logo_white : logo} alt="logo" width="250px" />
                </Link>
            </div>
        </>
    )
}