import ReactGA from "react-ga4";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";

import { useEffect, useRef } from "react";
import { useCollectEventMutation } from "../../store";

export default function LeftMenu() {
    const { nup } = useParams();
    const { searched } = useSelector((state) => state.history);

    const divRef = useRef(null);
    const activeLiRef = useRef(null);

    const existingDeviceId = localStorage.getItem('deviceId');
    const [collectEvent] = useCollectEventMutation();

    const eventServiceInformation = (to) => {
        const { category, action, label, value } = {
            category: 'Service Information',
            action: 'Interact',
            label: to,
            value: 1
        }
        ReactGA.event({ category, action, label, value });
        collectEvent({ deviceId: existingDeviceId, category, action, label, value });
    }

    useEffect(() => {
        if (divRef.current && activeLiRef.current) {
            const divElement = divRef.current;
            const activeLiElement = activeLiRef.current;
            const activeLiOffsetTop = activeLiElement.offsetTop;
            const activeLiOffsetHeight = activeLiElement.offsetHeight;
            const divScrollTop = divElement.scrollTop;
            const divOffsetHeight = divElement.offsetHeight;

            if (searched[0] === nup) {
                divElement.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            } else if (
                activeLiOffsetTop < divScrollTop ||
                activeLiOffsetTop + activeLiOffsetHeight > divScrollTop + divOffsetHeight
            ) {
                divElement.scrollTo({
                    top: (activeLiOffsetTop - (divOffsetHeight - activeLiOffsetHeight) / 2),
                    behavior: 'smooth'
                });
            }
        }
    }, [nup]);

    return (
        <nav className="sidenav left-menu pricing-box" style={{ display: "fixed" }}>
            <div className="inner">
                <div className="icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="62" height="64" viewBox="0 0 62 64">
                        <g fill="none" fillRule="evenodd">
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <g transform="translate(-535.000000, -1008.000000) translate(0.000000, 900.000000) translate(135.000000, 0.000000) translate(400.000000, 108.000000) translate(0.000000, 0.000000)">
                                                <path fill="#FF1F25" fillRule="nonzero" d="M62 10.706c0 5.913-4.73 10.706-10.567 10.706H18.028c-5.836 0-10.567-4.793-10.567-10.706C7.461 4.793 12.192 0 18.028 0h33.405C57.27 0 62 4.793 62 10.706z"></path>
                                                <rect width="54.539" height="21.412" x="7.461" y="35.227" fill="#FF1F25" fillRule="nonzero" rx="10.706" transform="translate(34.730623, 45.933484) rotate(-180.000000) translate(-34.730623, -45.933484)"></rect>
                                                <rect width="50.708" height="17.557" x="1" y="10.216" stroke="var(--sala-neutral-dark, $neutral_dark)" strokeWidth="2" rx="8.779" transform="translate(26.353898, 18.994105) rotate(-180.000000) translate(-26.353898, -18.994105)"></rect>
                                                <rect width="50.708" height="17.557" x="1" y="45.443" stroke="var(--sala-neutral-dark, $neutral_dark)" strokeWidth="2" rx="8.779" transform="translate(26.353898, 54.221490) rotate(-180.000000) translate(-26.353898, -54.221490)"></rect>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>
                <div className="name">Olá!</div>
                {(searched.length > 0) ?
                    <>
                        {(searched.length > 1) ?
                            <div className="price"><div className="number">{searched.length}</div> processos acompanhados</div>
                            :
                            <div className="price"><div className="number">{searched.length}</div> processo acompanhado</div>
                        }
                        <div style={{ height: "calc(100% - 100px)", overflow: "auto" }} ref={divRef}>
                            <ul className="list" style={{ paddingBottom: "40px" }}>
                                {searched.map((this_nup, i) => (
                                    <li className="active" key={i}>
                                        {this_nup === nup ?
                                            <Link
                                                to={"/" + this_nup}
                                                className={"link-active"}
                                                ref={activeLiRef}
                                            >
                                                {this_nup}
                                            </Link>
                                            :
                                            <Link
                                                to={"/" + this_nup}
                                            >
                                                {this_nup}
                                            </Link>
                                        }

                                    </li>
                                ))}
                            </ul>
                        </div>
                    </>
                    :
                    <div className="spaced justify-content-center align-items-center" style={{ height: "100%" }}>
                        <p>
                            Bem-vindo ao Justiça Simples! 👋 Seu assistente jurídico ultra rápido! 🚀
                        </p>
                        <p>
                            Não pesquisou nenhum processo ainda? Sem stress! Digite o número do processo para começar. Simples assim! 👌
                        </p>
                        <p>
                            Pronto para facilitar sua vida jurídica? 🎯 Temos certeza de que vai adorar! 💖
                        </p>
                        <p>Ah, os processos que você acompanha ficarão guardados aqui.</p>
                        <p>Boas pesquisas! 🎉</p>
                    </div>}
                <div className="button-wrap">
                    <Link to="/Bem-vindo!" className="button fullfield" title="Lista de espera"
                        onClick={() => eventServiceInformation('Bottom Menu')}
                    >
                        Pesquise um processo
                    </Link>
                    <Link to="/1040404-48.2022.8.26.0053" className="button" title="Lista de espera"
                        onClick={() => eventServiceInformation('Waiting List at App Left Menu')}
                    >
                        Ou veja um exemplo
                    </Link>
                </div>
            </div>
        </nav>
    )
}