import ReactGA from "react-ga4";
import {
    EmailIcon, EmailShareButton,
    FacebookShareButton, FacebookIcon,
    FacebookMessengerShareButton, FacebookMessengerIcon,
    TwitterShareButton, TwitterIcon, TelegramShareButton, TelegramIcon, WhatsappShareButton, WhatsappIcon, LinkedinShareButton, LinkedinIcon,
} from 'react-share';
import illustration from "../../../../assets/images/coming-soon.svg";
import { useCollectEventMutation } from "../../../../store";

const existingDeviceId = localStorage.getItem('deviceId');

export default function SectionShare() {
    const [collectEvent] = useCollectEventMutation();
    const niceMsg = `Oi! 😊

Queria te contar sobre o Justiça Simples, uma ferramenta incrível que descobri recentemente. 🚀
    
Ela centraliza informações de todos os tribunais em um só lugar, o que facilita muito o acompanhamento de processos. ⚖️📚
    
Você só precisa inserir o número do processo neste link: https://justicasimpl.es/0000000-00.0000.0.00.0000. Acho que vai te ajudar bastante e economizar muito tempo. ⏳💡
    
Dá uma olhada quando tiver um tempinho: https://justicasimpl.es/.
    
Tenho certeza de que vai gostar! 👍🎉`

    const nicePost = `👋 Pessoal! Descobri uma ferramenta maravilhosa chamada Justiça Simples! 💼⚖️ Centraliza informações de todos os tribunais em um lugar. Muito prático!

Acompanhe seus processos diretamente, é só colocar o número no link: https://justicasimpl.es/0000000-00.0000.0.00.0000 🚀
    
Acessem: https://justicasimpl.es/ e vejam como é simples! 👍🎉 #JustiçaSimples #DicaDoDia`

    const formalMsg = `Caros colegas de profissão,

Recentemente, tive a oportunidade de explorar uma ferramenta que considero ser uma verdadeira inovação para nosso campo - a Justiça Simples. Trata-se de uma plataforma que agrega informações de todos os tribunais em um único local, tornando o acompanhamento de processos muito mais simples e eficiente.
    
Para acessar um processo específico, basta incluir o número do processo no final deste link: https://justicasimpl.es/0000000-00.0000.0.00.0000. Acredito que essa ferramenta pode ser de grande utilidade para muitos de nós, economizando tempo e otimizando nossos processos de trabalho.
    
Convido todos a conferir: https://justicasimpl.es/. Vamos juntos tornar nossa interação com a justiça mais prática e menos estressante.
    
#JustiçaSimples #Inovação #Direito`

    const mailMsg = `Olá!

Acabei de descobrir o Justiça Simples, um serviço gratuito incrível que permite acessar informações sobre processos judiciais de forma rápida e simples. Agora você pode encontrar qualquer processo em um só lugar, evitando o trabalho de navegar em vários sites de tribunais.

Lembrei de você. Experimente o Justiça Simples e depois me conte como ele simplificou a sua rotina jurídica!

Acesse: https://justicasimpl.es/

`

    const collectShare = (media) => {
        const { category, action, label, value } = {
            category: 'Service Sharing',
            action: 'Share',
            label: media,
            value: 1
        }
        ReactGA.event({ category, action, label, value });
        collectEvent({ deviceId: existingDeviceId, category, action, label, value });
    }

    return (
        <>
            <section className="section background-yellow spdtb">
                <div className="container">
                    <div className="row flex-align-c">
                        <div className="col-lg-6">
                            <div className="pdr100">
                                <div className="heading heading-alway-dark mb24">
                                    <div className="heading-sub">Compartilhe essa ideia</div>
                                    <h2 className="heading-title size-xl">Mostre a todos o caminho mais simples!</h2>
                                </div>
                                <FacebookShareButton
                                    url="https://justicasimpl.es/"
                                    onClick={() => collectShare('Facebook')}
                                    quote={nicePost}
                                    hashtag="#JustiçaSimples #ProcessosJudiciais"
                                >
                                    <FacebookIcon size={64} round />
                                </FacebookShareButton>

                                <FacebookMessengerShareButton
                                    url="https://justicasimpl.es/"
                                    onClick={() => collectShare('Facebook Messenger')}
                                    appId="811166619986816"
                                    redirectUri="https://justicasimpl.es/"
                                >
                                    <FacebookMessengerIcon size={64} round />
                                </FacebookMessengerShareButton>

                                <TwitterShareButton
                                    url="https://justicasimpl.es/"
                                    onClick={() => collectShare('Twitter')}
                                    title="Descobri o #JustiçaSimples, ferramenta que centraliza todos os tribunais num lugar só! 🚀 Economize tempo acompanhando processos: basta inserir o número no link https://justicasimpl.es/0000000-00.0000.0.00.0000. Simples e prático! Confira: https://justicasimpl.es/ 💼⚖️👍 #DicaDoDia"
                                    via="JusticaSimples"
                                    hashtags={['JustiçaSimples', 'ProcessosJudiciais']}
                                    related={['JusticaSimples']}
                                >
                                    <TwitterIcon size={64} round />
                                </TwitterShareButton>

                                <TelegramShareButton
                                    url="https://justicasimpl.es/"
                                    onClick={() => collectShare('Telegram')}
                                    title={niceMsg}
                                >
                                    <TelegramIcon size={64} round />
                                </TelegramShareButton>

                                <WhatsappShareButton
                                    url="https://justicasimpl.es/"
                                    onClick={() => collectShare('Whatsapp')}
                                    title={niceMsg}
                                    separator=" - "
                                >
                                    <WhatsappIcon size={64} round />
                                </WhatsappShareButton>

                                <LinkedinShareButton
                                    url="https://justicasimpl.es/"
                                    onClick={() => collectShare('Linkedin')}
                                    title="Descubra o Justiça Simples - a maneira mais fácil de acessar informações sobre processos judiciais! Pesquise processos em segundos e simplifique sua rotina jurídica. Experimente agora mesmo!"
                                    summary="Pesquise processos judiciais em segundos e simplifique sua rotina jurídica com o Justiça Simples. Experimente agora mesmo!"
                                    source="Justiça Simples"
                                    quote={formalMsg}
                                >
                                    <LinkedinIcon size={64} round />
                                </LinkedinShareButton>

                                <EmailShareButton
                                    url="https://justicasimpl.es/"
                                    onClickCapture={() => collectShare('Email')}
                                    subject="Descubra o Justiça Simples - a maneira mais fácil de acessar informações sobre processos judiciais!"
                                    body={mailMsg}
                                >
                                    <EmailIcon size={64} round />
                                </EmailShareButton>

                            </div>
                        </div>
                        <div className="col-lg-6 hidden-sm">
                            <img src={illustration} alt="Illustration" style={{ marginTop: "-110px", marginBottom: "-94px" }} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}