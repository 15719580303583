import ReactGA from "react-ga4";
import character from "../../img/characters/nice-guy.svg";
import { useSelector } from "react-redux";
import { useCollectEventMutation } from "../../store";

import like from "../../img/ui/like.svg";
import dislike from "../../img/ui/dislike.svg";

const NiceGuy = () => {
    const { right_menu } = useSelector((state) => state.ui);

    const existingDeviceId = localStorage.getItem('deviceId');
    const [collectEvent] = useCollectEventMutation();

    const eventAppInteraction = (to) => {
        const { category, action, label, value } = {
            category: 'App',
            action: 'Interact',
            label: to,
            value: 1
        }
        ReactGA.event({ category, action, label, value });
        collectEvent({ deviceId: existingDeviceId, category, action, label, value });
    }


    return (
        <div className={"nice-guy " + (right_menu && "above")}
            onClick={() => eventAppInteraction('Clicked Nice Guy')}
        >
            {false &&
                <div className="bubble speech">
                    O que você achou desse resultado?
                    <div className="row">
                        <img src={like} className="thumbs" />
                        <img src={dislike} className="thumbs" />
                    </div>
                </div>
            }
            <img src={character} alt="Cara legal" />
        </div>
    )
}

export default NiceGuy;