import ReactGA from "react-ga4";
import { Link } from "react-router-dom";
import { useCollectEventMutation } from "../../../store";

const existingDeviceId = localStorage.getItem('deviceId');

export default function Footer() {
    const [collectEvent] = useCollectEventMutation();

    const eventServiceInformation = (to) => {        
        const { category, action, label, value } = {
            category: 'Service Information',
            action: 'Interact',
            label: to,
            value: 1
        }
        ReactGA.event({ category, action, label, value });
        collectEvent({ deviceId: existingDeviceId, category, action, label, value });
    } 

    return (
        <>
            <footer id="footer" className="footer">
                {/* <div className="footer-top">
                    <div className="container">
                        <div className="inner">
                            <div className="row">
                                <div className="col-lg-3">
                                    <div className="footer-item footer-about">
                                        <Logo />
                                        <p>
                                            Democratizando o acesso à justiça. Uma consulta de processo por vez.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="footer-item footer-menu">
                                        <h6>Empresa</h6>
                                        <ul>
                                            <li>
                                                <Link to="/sobre-nos" title="Sobre nós">
                                                    Sobre nós
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/blog" title="Blog">
                                                    Blog
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/carreiras" title="Carreiras">
                                                    Carreiras
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/contato" title="Contato">
                                                    Contato
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="footer-item footer-menu">
                                        <h6>Recursos</h6>
                                        <ul>
                                            <li>
                                                <Link to="/downloads" title="Downloads">
                                                    Downloads
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/centro-de-ajuda" title="Centro de Ajuda">
                                                    Centro de Ajuda
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/parceiros" title="Parceiros">
                                                    Parceiros
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/kit-de-imprensa" title="Kit de Imprensa">
                                                    Kit de Imprensa
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="footer-item footer-menu">
                                        <h6>Redes Sociais</h6>
                                        <ul>
                                            <li>
                                                <Link to="https://www.facebook.com" title="Facebook">
                                                    Facebook
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="https://www.twitter.com" title="Twitter">
                                                    Twitter
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="https://www.instagram.com" title="Instagram">
                                                    Instagram
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="https://www.linkedin.com" title="LinkedIn">
                                                    LinkedIn
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="footer-item footer-newsletter">
                                        <h6>Newsletter</h6>
                                        <p>
                                            Inscreva-se em nossa newsletter e mantenha-se atualizado sobre o Justiça Simples
                                        </p>
                                        <form action="#" className="newsletter">
                                            <div className="field-input">
                                                <input
                                                    type="email"
                                                    defaultValue=""
                                                    placeholder="E-mail"
                                                />
                                            </div>
                                            <div className="field-submit">
                                                <button>Inscrever-se</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="footer-bottom">
                    <div className="container">
                        <div className="inner flex flex-content-sb flex-align-c">
                            <div className="copyright">© 2023 Justiça Simples é uma marca registrada licenciada à Benti Technologies.</div>
                            <div className="menu">
                                <ul>
                                    <li>
                                        <Link to="/termos" title="Terms" onClick={() => eventServiceInformation('Terms Link at Footer')}>
                                            Termos de uso
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/privacidade" title="Privacy Policy" onClick={() => eventServiceInformation('Privacy Link at Footer')}>
                                            Política de privacidade
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}