import { useSelector } from "react-redux";

const LabMain = () => {
    const { selectedJurisdiction, selectedExample, selectedUrl } = useSelector((state) => state.labUi);
    const hasUrl = selectedJurisdiction && selectedJurisdiction.return && selectedJurisdiction.return.url;
    const hasExamples = selectedJurisdiction && selectedJurisdiction.examples;

    if (!hasUrl) {
        return "Defina uma url"
    }

    if (!hasExamples) {
        return "Inclua um exemplo para testar"
    }

    const nup = selectedJurisdiction.examples[0];
    const parts = {}
    parts.NNNNNNN = nup.substring(0, 7);
    parts.NNNNNN = nup.substring(1, 7);
    parts.DD = nup.substring(8, 10);
    parts.AAAA = nup.substring(11, 15);
    parts.JTR = nup.substring(16, 20);
    parts.J = nup.substring(16, 17);
    parts.TR = nup.substring(18, 20);
    parts.OOOO = nup.substring(21, 25);
    parts.OOO = nup.substring(21, 24);
    parts.OO = nup.substring(21, 23);

    const jsurl = "https://justicasimples.com.br/" + nup;

    const url = selectedJurisdiction.return.url
        .replaceAll('https', 'http')
        .replaceAll('[NNNNNNN]', parts.NNNNNNN)
        .replaceAll('[NNNNNN]', parts.NNNNNN)
        .replaceAll('[DD]', parts.DD)
        .replaceAll('[AAAA]', parts.AAAA)
        .replaceAll('[JTR]', parts.JTR)
        .replaceAll('[J]', parts.J)
        .replaceAll('[TR]', parts.TR)
        .replaceAll('[OOOO]', parts.OOOO)
        .replaceAll('[OOO]', parts.OOO)
        .replaceAll('[OO]', parts.OO)
    return (
        <>
            <h3><a href={jsurl} target="_blank">{jsurl}</a></h3>
            <small>{url}</small>
            <iframe src={url} style={{ width: "100%", height: "100%" }} />
        </>
    )
}

export default LabMain;