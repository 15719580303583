import { createSlice } from '@reduxjs/toolkit';

const uiSlice = createSlice({
    name: "ui",
    initialState: {
        deviceId: null,
        left_menu: true,
        right_menu: true,
        fullscreen: false,
        notifications: [],
        elapsedTime: 0,
        lastPath: null,
        top_menu_visible: null
    },
    reducers: {
        setDeviceId(state, action) {
            state.deviceId = action.payload;
        },
        setLeftMenu(state, action) {
            state.left_menu = action.payload;
        },
        setRightMenu(state, action) {
            state.right_menu = action.payload;
        },
        setFullscreen(state, action) {
            state.fullscreen = action.payload;
        },
        notify(state, action) {
            state.notifications.push(action.payload);
        },
        notified(state, action) {
            state.notifications.shift();
        },
        setElapsedTime(state, action) {
            state.elapsedTime = action.payload;
        },
        setLastPath(state, action) {
            state.lastPath = action.payload;
        },
        setTopMenuVisible(state, action) {
            state.top_menu_visible = action.payload;
        },
    }
})

export default uiSlice.reducer
export const {
    setDeviceId,
    setLeftMenu,
    setRightMenu,
    setFullscreen,
    notify, notified,
    setElapsedTime,
    setLastPath,
    setTopMenuVisible
} = uiSlice.actions