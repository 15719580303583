

const Party = () => {
    return (
        <div className="background images layout-05">
                <div className="inner fill relative">
                    <img className="img02" src="assets/images/saas-02.png" alt="Image" style={{visibility: "visible", animationDelay: "0.5s", animationName: "zoomIn"}} />
                    <img className="img03" src="assets/images/saas-03.png" alt="Image" style={{visibility: "visible", animationDelay: "0.3s", animationName: "zoomIn"}} />
                    <img className="img04" src="assets/images/saas-04.png" alt="Image" style={{visibility: "visible", animationDelay: "0.1s", animationName: "zoomIn"}} />
                    <img className="img05" src="assets/images/saas-02.png" alt="Image" style={{visibility: "visible", animationDelay: "0.5s", animationName: "zoomIn"}} />
                    <img className="img06" src="assets/images/saas-03.png" alt="Image" style={{visibility: "visible", animationDelay: "0.9s", animationName: "zoomIn"}} />
                    <img className="img07" src="assets/images/saas-04.png" alt="Image" style={{visibility: "visible", animationDelay: "0.7s", animationName: "zoomIn"}} />
                </div>
            </div>
    )
}

export default Party;