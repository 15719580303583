import ReactGA from "react-ga4";
import { useDispatch, useSelector } from "react-redux";
import { setFullscreen, useCollectEventMutation } from "../../store";
import { useEffect } from "react";

const TrafficLights = () => {
    const { fullscreen } = useSelector((state) => state.ui);
    const dispatch = useDispatch();
    
    const existingDeviceId = localStorage.getItem('deviceId');
    const [collectEvent] = useCollectEventMutation();

    const eventAppInteraction = (to) => {        
        const { category, action, label, value } = {
            category: 'App',
            action: 'Interact',
            label: to,
            value: 1
        }
        ReactGA.event({ category, action, label, value });
        collectEvent({ deviceId: existingDeviceId, category, action, label, value });
    }

    useEffect(() => {
        const browser = document.getElementById("browser");

        if (fullscreen) {
            if (browser.requestFullscreen) {
                browser.requestFullscreen();
            } else if (browser.webkitRequestFullscreen) { /* Safari */
                browser.webkitRequestFullscreen();
            } else if (browser.msRequestFullscreen) { /* IE11 */
                browser.msRequestFullscreen();
            }
        } else {
            if (document.fullscreenElement) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) { /* Safari */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE11 */
                document.msExitFullscreen();
            }
        }
    }, [fullscreen])

    const handleFullscreen = () => {
        dispatch(setFullscreen(!fullscreen));
        eventAppInteraction('Fullscreen at Traffic Lights');
    }

    return (
        <div className="browser-traffic-light" onClick={handleFullscreen} title="Abrir em tela cheia">
            <div className="red"></div>
            <div className="yellow"></div>
            <div className="blue"></div>
        </div>
    )
}

export default TrafficLights;