import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const roadmapApi = createApi({
    reducerPath: "roadmap",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.NODE_ENV === "production" ? process.env.CLIENT : "http://localhost:3000"
    }),
    endpoints(builder) {
        return {
            fetchRoadmap: builder.query({
                query: (nup) => {
                    return {
                        url: `/api/roadmap`,
                        method: 'GET'
                    }
                },
                providesTags: ['roadmap']
            }),
            voteRoadmap: builder.mutation({
                query: ({JTR, instance, direction}) => {
                    return {
                        url: `/api/roadmap/vote/${JTR}/${instance}/${direction}`,
                        method: 'POST'
                    }
                },
                invalidatesTags: ['roadmap']
            }),
        }
    }
});

export const {
    useFetchRoadmapQuery,
    useVoteRoadmapMutation
} = roadmapApi;
export { roadmapApi };