import ReactGA from 'react-ga4';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setElapsedTime, setLastPath, useCollectPageviewMutation } from '../../store';

const PageView = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    const { elapsedTime, lastPath } = useSelector((state) => state.ui);
    const existingDeviceId = localStorage.getItem('deviceId');

    const [isActive, setIsActive] = useState(true);
    const startTimeRef = useRef(Date.now());

    const [collectPageview] = useCollectPageviewMutation();

    useEffect(() => {
        ReactGA.send({ hitType: 'pageview', page: location.pathname, title: document.title });
    }, [location]);

    useEffect(() => {
        let timer;
        let startTime;

        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                startTime = Date.now();
                setIsActive(true);
            } else {
                if (isActive) {
                    const endTime = Date.now();
                    const elapsedSeconds = Math.floor((endTime - startTime) / 1000);
                    dispatch(setElapsedTime(elapsedTime + elapsedSeconds));
                }
                setIsActive(false);
            }
        };

        const handleBeforeUnload = () => {
            const endTime = Date.now();
            const elapsedSeconds = Math.floor((endTime - startTimeRef.current) / 1000);
            const timeSpent = isActive ? elapsedTime + elapsedSeconds : elapsedTime;

            collectPageview({
                deviceId: existingDeviceId,
                page: location.pathname,
                time: timeSpent,
            });
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);
        window.addEventListener('beforeunload', handleBeforeUnload);

        if (document.visibilityState === 'visible') {
            startTime = Date.now();
        }

        timer = setInterval(() => {
            if (isActive) {
                dispatch(setElapsedTime(elapsedTime + 1));
            }
        }, 1000);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            window.removeEventListener('beforeunload', handleBeforeUnload);
            clearInterval(timer);
        };
    }, [location, isActive, elapsedTime, collectPageview, existingDeviceId, dispatch]);

    useEffect(() => {
        dispatch(setLastPath(location.pathname));
        dispatch(setElapsedTime(0));
    }, [location, dispatch])

    useEffect(() => {
        if ((lastPath !== location.pathname) && elapsedTime > 0) {
            collectPageview({
                deviceId: existingDeviceId,
                page: lastPath,
                time: elapsedTime,
            });
        }
    }, [location, elapsedTime, collectPageview, existingDeviceId, lastPath]);

}

export default PageView;