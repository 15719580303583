import ReactGA from "react-ga4";
import { useState } from "react";
import { useCollectEventMutation, useCollectFeedbackMutation } from "../../store";
import { Link } from "react-router-dom";

const FormFeedback = ({ question, placeholder, button, title, subtitle, finalMessage, secondaryButton }) => {
    const [collectFeedback] = useCollectFeedbackMutation();
    const [value, setValue] = useState();
    const [submit, setSubmit] = useState(false);

    const existingDeviceId = localStorage.getItem('deviceId');
    const [collectEvent] = useCollectEventMutation();

    const eventAppInteraction = (to) => {
        const { category, action, label, value } = {
            category: 'App',
            action: 'Interact',
            label: to,
            value: 1
        }
        ReactGA.event({ category, action, label, value });
        collectEvent({ deviceId: existingDeviceId, category, action, label, value });
    }

    const handleSubmit = () => {
        collectFeedback({ deviceId: existingDeviceId, feedback: value, nup: subtitle });
        setSubmit(true);
        eventAppInteraction('Submit Feedback at Browser');
    }

    return (
        <>
            {submit ?
                <h3>{finalMessage}</h3>
                :
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        {title &&
                            <div className="col-md-12">
                                <h4>{title}</h4>
                                <h3>{subtitle}</h3>
                                <br />
                            </div>
                        }
                        <div className="col-md-12">
                            <div className="field-input field-textarea">
                                <label htmlFor="message">{question}</label>
                                <textarea name="message" id="message" cols="30" rows="10" placeholder={placeholder} value={value} onChange={(event) => setValue(event.target.value)} autoFocus />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="field-submit">
                                <input type="submit" value={button} name="submit" />
                                {secondaryButton && <Link to="/roadmap" className="button cursor">Ver Roadmap</Link>}
                            </div>
                        </div>
                    </div>
                </form>
            }
        </>
    )
}

export default FormFeedback;