import ReactGA from "react-ga4";
import { useParams } from "react-router-dom";

import {
    EmailShareButton, EmailIcon,
    FacebookShareButton, FacebookIcon,
    FacebookMessengerShareButton, FacebookMessengerIcon,
    TwitterShareButton, TwitterIcon,
    TelegramShareButton, TelegramIcon,
    WhatsappShareButton, WhatsappIcon,
    LinkedinShareButton, LinkedinIcon,
} from 'react-share';
import { useCollectEventMutation, useFetchNupQuery } from "../../store";
import { useSelector } from "react-redux";

const existingDeviceId = localStorage.getItem('deviceId');

export default function ShareMenu() {
    const [collectEvent] = useCollectEventMutation();

    const collectShare = (media) => {
        const { category, action, label, value } = {
            category: 'Service Sharing',
            action: 'Share NUP',
            label: media,
            value: 1
        }
        ReactGA.event({ category, action, label, value });
        collectEvent({ deviceId: existingDeviceId, category, action, label, value });
    }

    const { nup } = useParams();
    const valid_nup = (/^([0-9]{7}-[0-9]{2}\.[0-9]{4}\.[0-9]\.[0-9]{2}\.[0-9]{4})$/.test(nup));
    const { data, isError, isFetching, isLoading } = useFetchNupQuery(valid_nup ? nup : null);
    const nup_if_valid = (!(isError || isFetching || isLoading) && data && valid_nup) ? nup : "";
    const { right_menu } = useSelector((state) => state.ui);

    if (right_menu) {
        return (
            <div className="share-menu">

                <FacebookShareButton
                    url={`https://justicasimpl.es/${nup_if_valid}`}
                    onClick={() => collectShare('Facebook')}
                    quote={`Descubra o Justiça Simples e acesse informações sobre o processo ${nup_if_valid} em um só lugar. É rápido e prático!`}
                    hashtag="#JustiçaSimples #ProcessosJudiciais"
                >
                    <FacebookIcon size={64} round />
                </FacebookShareButton>

                <FacebookMessengerShareButton
                    url={`https://justicasimpl.es/${nup_if_valid}`}
                    onClick={() => collectShare('Facebook Messenger')}
                    appId="yourAppId"
                    redirectUri="yourRedirectUri"
                    to="userId"
                >
                    <FacebookMessengerIcon size={64} round />
                </FacebookMessengerShareButton>

                <TelegramShareButton
                    url={`https://justicasimpl.es/${nup_if_valid}`}
                    onClick={() => collectShare('Telegram')}
                    title={`Descubra o Justiça Simples e acesse informações sobre o processo ${nup_if_valid} em um só lugar. É rápido e prático!`}
                >
                    <TelegramIcon size={64} round />
                </TelegramShareButton>

                <WhatsappShareButton
                    url={`https://justicasimpl.es/${nup_if_valid}`}
                    onClick={() => collectShare('Whatsapp')}
                    title={`Descubra o Justiça Simples e acesse informações sobre o processo ${nup_if_valid} em um só lugar. É rápido e prático!`}
                >
                    <WhatsappIcon size={64} round />
                </WhatsappShareButton>

                <LinkedinShareButton
                    url={`https://justicasimpl.es/${nup_if_valid}`}
                    onClick={() => collectShare('Linkedin')}
                    title={`Descubra o Justiça Simples e acesse informações sobre o processo ${nup_if_valid} em um só lugar. É rápido e prático!`}
                    summary="Pesquise processos judiciais em segundos e simplifique sua rotina jurídica com o Justiça Simples. Experimente agora mesmo!"
                    source="Justiça Simples"
                >
                    <LinkedinIcon size={64} round />
                </LinkedinShareButton>

                <EmailShareButton
                    url={`https://justicasimpl.es/${nup_if_valid}`}
                    onClickCapture={() => collectShare('Email')}
                    subject={`Informações sobre o processo ${nup_if_valid}`}
                    body={`Olá! 

Seguem as informações sobre o processo ${nup_if_valid}. 

Você pode acessar pelo Justiça Simples de forma rápida e simples. 
Basta clicar neste link: https://justicasimpl.es/${nup_if_valid}

`}>
                    <EmailIcon size={64} round />
                </EmailShareButton>

            </div>
        )
    }
}