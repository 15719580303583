import ReactGA from "react-ga4";
import { useCollectEventMutation } from "../../../../store";

import { useState } from "react"

import image from "../../../../assets/images/banner-image-05.png";
import { useDispatch } from "react-redux";
import { notified, notify } from "../../../../store";
import { MaskedInput } from "grommet";

export default function SectionCreateLink() {
    const [nup, setNup] = useState('');
    const valid_nup = (/^([0-9]{7}-[0-9]{2}\.[0-9]{4}\.[0-9]\.[0-9]{2}\.[0-9]{4})$/.test(nup));
    const dispatch = useDispatch();

    const existingDeviceId = localStorage.getItem('deviceId');
    const [collectEvent] = useCollectEventMutation();

    const eventServiceInformation = (to) => {        
        const { category, action, label, value } = {
            category: 'Service Information',
            action: 'Interact',
            label: to,
            value: 1
        }
        ReactGA.event({ category, action, label, value });
        collectEvent({ deviceId: existingDeviceId, category, action, label, value });
    } 

    const handleCopy = (event) => {
        event.preventDefault();
        navigator.clipboard.writeText('https://justicasimpl.es/' + (valid_nup ? nup : ""));
        dispatch(notify({ title: "Copiado", message: "Link copiado para a área de trabalho!" }));
        setTimeout(() => {
            dispatch(notified());
        }, 2000);
        eventServiceInformation('Copy Button at CreateLink Section');
    }

    return (
        <>

            <section className="section-banner background-navy layout-02">
                <div className="container">
                    <div className="inner background-navy">
                        <div className="row flex-align-c">
                            <div className="col-lg-5">
                                <div className="images layout-02">
                                    <div className="inner">
                                        <img className="img31" src={image} alt="Image" style={{ marginTop: "-20px", marginBottom: "-13px", transform: "scaleX(-1)" }} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="content align-left pdr80 lg-pdl30 lg-pdr30 lg-mt32 lg-pdb30">
                                    <div className="heading heading-alway-white mb32">
                                        <h2 className="heading-title size-l">Crie um link <span className="border-yellow">Justiça Simples</span></h2>
                                        <div className="heading-desc">Digite o número único do processo:</div>
                                    </div>
                                    <form action="#" className="newsletter" onSubmit={handleCopy}>
                                        <div className="field-input">
                                            <MaskedInput
                                                mask={[
                                                    {
                                                        length: 7,
                                                        regexp: /^[0-9]{1,7}$/,
                                                        placeholder: '_______',
                                                    },
                                                    { fixed: '-' },
                                                    {
                                                        length: 2,
                                                        regexp: /^[0-9]{1,2}$/,
                                                        placeholder: '__',
                                                    },
                                                    { fixed: '.' },
                                                    {
                                                        length: 4,
                                                        regexp: /^[0-9]{1,4}$/,
                                                        placeholder: '____',
                                                    },
                                                    { fixed: '.' },
                                                    {
                                                        length: 1,
                                                        regexp: /^[0-9]{1}$/,
                                                        placeholder: '_',
                                                    },
                                                    { fixed: '.' },
                                                    {
                                                        length: 2,
                                                        regexp: /^[0-9]{1,2}$/,
                                                        placeholder: '__',
                                                    },
                                                    { fixed: '.' },
                                                    {
                                                        length: 4,
                                                        regexp: /^[0-9]{1,4}$/,
                                                        placeholder: '____',
                                                    },
                                                ]}
                                                value={nup}
                                                onChange={(event) => setNup(event.target.value)}
                                                onPaste={(event) => {
                                                    const paste_filtered = event.clipboardData.getData('text/plain').replace(/\D/g,'');
                                                    const combine = nup + paste_filtered;
                                                    const limited_size = combine.substring(0, 25);
                                                    setNup(limited_size);
                                                }}
                                                name="nup"
                                                style={{ height: "60px", backgroundColor: "#FFFFFF" }}
                                            />
                                        </div>
                                        <div className="field-submit">
                                            <input type="submit" name="submit" value="Copiar" />
                                        </div>
                                        <div className="field-note">
                                            <ul>
                                                <li>https://justicasimpl.es/{nup}</li>
                                            </ul>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}