import ReactGA from "react-ga4";
import reload from "../../img/browser/reload.svg";
import lock from "../../img/browser/lock.svg";
import ClickableImage from "../elements/ClickableImage";
import { useFetchNupQuery, useCollectEventMutation } from "../../store";
import { useParams } from "react-router-dom";

const SearchBarText = () => {
    const { nup } = useParams();
    const valid_nup = (/^([0-9]{7}-[0-9]{2}\.[0-9]{4}\.[0-9]\.[0-9]{2}\.[0-9]{4})$/.test(nup));
    const { data, isError, isFetching, isLoading } = useFetchNupQuery(valid_nup ? nup : null);

    if (isError) {
        return (
            <div className="browser-bar-shrink" title="Ocorreu algum erro ao tentar acessar esse processo">
                <div className="browser-bar-text">Oops</div>
            </div>
        )
    } else if (isFetching || isLoading) {
        return (
            <div className="browser-bar-shrink">
                <ClickableImage imageClass="browser-bar-lock" alt="Seguro" image={lock} disabled={false} />
                <div className="browser-bar-text">...</div>
            </div>
        )
    } else if (data) {
        return (
            <div className="browser-bar-shrink">
                <ClickableImage imageClass="browser-bar-lock" alt="Seguro" image={lock} disabled={false} />
                <div className="browser-bar-text">{data.display}</div>
            </div>
        )
    }


}

const SearchBar = () => {
    const existingDeviceId = localStorage.getItem('deviceId');
    const [collectEvent] = useCollectEventMutation();

    const eventAppInteraction = (to) => {
        const { category, action, label, value } = {
            category: 'App',
            action: 'Interact',
            label: to,
            value: 1
        }
        ReactGA.event({ category, action, label, value });
        collectEvent({ deviceId: existingDeviceId, category, action, label, value });
    }

    return (
        <div className="browser-bar" onClick={() => eventAppInteraction('SearchBar at Browser')}>
            <div className="browser-bar-core">
                <div className="d-flex justify-content-center align-items-center">
                    <SearchBarText />
                </div>
                <ClickableImage imageClass="browser-bar-reload" alt="Recarregar página" image={reload} disabled={false} />
            </div>
        </div>

    )
}

export default SearchBar;