import { useRef } from "react";

const ClickableImage = ({ imageClass, alt, image, disabled, ...rest }) => {
    const imageRef = useRef(null);

    return (
        <img
            className={imageClass + " svg-actionable"}
            alt={alt}
            title={alt}
            src={image}
            ref={imageRef}
            {...rest}
        />
    )
}


export default ClickableImage;