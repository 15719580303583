import ReactGA from "react-ga4";
import { Link } from "react-router-dom";
import { useCollectEventMutation } from "../../../store";

const existingDeviceId = localStorage.getItem('deviceId');

export default function HeaderRight() {
    const [collectEvent] = useCollectEventMutation();

    const eventServiceInformation = (to) => {
        const { category, action, label, value } = {
            category: 'Service Information',
            action: 'Interact',
            label: to,
            value: 1
        }
        ReactGA.event({ category, action, label, value });
        collectEvent({ deviceId: existingDeviceId, category, action, label, value });
    }

    return (
        <div className="header-right flex flex-align-c flex-content-e">
            <div className="customer">
                <Link to="/Bem-vindo!" onClick={() => eventServiceInformation('Free')}>
                    <i className="las la-user-circle" />
                    <span>Gratuito</span>
                </Link>
            </div>
            <div className="buttons">
                <Link to="/Bem-vindo!" className="button fullfield" onClick={() => eventServiceInformation('Search Button Top Right')}>
                    <i className="las la-search" />
                    <span>Pesquisar</span>
                </Link>
            </div>
        </div>
    )
}