import ReactGA from "react-ga4";
import { useEffect } from "react";
import Layout from "../components/layout/Layout";
import { useCollectEventMutation } from "../store";
import FormFeedback from "../components/elements/FormFeedback";
import RoadmapTable from "../components/admin/roadmap/RoadmapTable";

const existingDeviceId = localStorage.getItem('deviceId');

const Roadmap = () => {
    const [collectEvent] = useCollectEventMutation();

    useEffect(() => {
        document.body.classList.add('sala-dark-scheme');
    }, [])

    useEffect(() => () => {
        document.body.classList.remove('sala-dark-scheme');
    }, []);

    const eventServiceInformation = (to) => {
        const { category, action, label, value } = {
            category: 'Company',
            action: 'Interest',
            label: to,
            value: 1
        }
        ReactGA.event({ category, action, label, value });
        collectEvent({ deviceId: existingDeviceId, category, action, label, value });
    }

    return (
        <Layout siteContentClass="pt0" headerLayout="dev">
            <section className="section-table spdtb">
                <div className="container">
                    <div className="heading align-center">
                        <h1 className="title">Nosso Roadmap</h1>
                        <p className="heading-desc">Descubra o que estamos construindo para tornar sua jornada ainda mais simples!</p>
                    </div>
                    <div className="row">
                        <div style={{ padding: "40px" }} className="col-lg-7">
                            <h3>🎉 Olá, Comunidade!</h3>
                            <br />
                            <p>
                                Na Justiça Simples, acreditamos que transparência e colaboração são fundamentais para construir o melhor serviço de consultas processuais.
                                Por isso, estamos super felizes em compartilhar nosso Roadmap público. Aqui você encontrará o que estamos desenvolvendo, as ideias que temos para o futuro, e o que acabou de ser lançado.
                            </p>
                            <br />
                            <p>💡 Sua opinião é valiosa! Se tiver sugestões ou feedback, por favor, não hesite em compartilhar conosco. Aproveite para votar abaixo nos tribunais que são mais importantes para você.</p>
                        </div>
                        <div style={{ padding: "40px" }} className="col-lg-5">
                            <FormFeedback
                                placeholder="Escreva aqui seu comentário ou sugestão"
                                button="Enviar mensagem"
                                finalMessage="Muito obrigado por contribuir para a nossa missão de simplificar o Direito!"
                            />
                        </div>
                    </div>
                    <RoadmapTable />
                </div>
            </section>
            <section className="section align-center spdb" id="work">
                <div className="block-trial layout-01">
                    <div className="container">
                        <img className="image-top" src="/assets/images/macaroni-vertical.webp" alt="Trial" />
                        <div className="sub">Venha fazer parte do nosso time</div>
                        <h2 className="title">Estamos contratando!</h2>
                        <div className="button-wrap"><a href="mailto:recrutamento@justicasimples.com.br" className="button fullfield" title="Fale conosco" onClick={() => eventServiceInformation('Recruiting Button Bottom')}>Fale conosco</a></div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Roadmap;